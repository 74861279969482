import { useNavigate } from 'react-router-dom';
import { Select } from 'antd';
import React, { useContext } from 'react';

import {
  PercentagePieChart,
  ISelectOption,
  MyIcon,
  filterOptions,
  DealershipContext,
  PrimaryNavigation,
  IDashboard,
  Total,
  UserContext,
} from '@scanny-app/loopy-loop';

interface DashboardProps {
  toggleSidebar?: (() => void) | undefined;
  sidebarVisibleInMobile?: boolean | undefined;
  collectDataDashboard: IDashboard;
}

function Dashboard({ toggleSidebar, sidebarVisibleInMobile, collectDataDashboard }: DashboardProps) {
  const navigate = useNavigate();
  const userData = useContext(UserContext);
  const { program, location, setReloadDashboard, rules } = useContext(DealershipContext);
  const { Option } = Select;
  const isManufacturer = userData?.profileType === 'Manufacturer';

  const rightNode = (
    <>
      {userData?.profileType === 'Dealer' && (
        <button
          className="btn-secondary flex middle flex-row md-text-hide gap-12 md-btn-transparent md-icon"
          onClick={() => navigate('/rentals/rental-requests/add')}
        >
          <MyIcon icon="IconRentalRequest" />
          <span>New rental request</span>
        </button>
      )}
    </>
  );

  const handleProgramFilter = (values: string[]) => {
    program.setSelectedProgram(values);
    setReloadDashboard(true);
  };

  const handleLocationFilter = (values: string[]) => {
    location.setSelectedLocation(values);
    setReloadDashboard(true);
  };
  return (
    <>
      <PrimaryNavigation
        toggleSidebar={toggleSidebar}
        sidebarVisible={sidebarVisibleInMobile}
        rightNode={rightNode}
        menuTitle={`${userData?.companyName} Dashboard`}
        menuIcon={'IconDashboard'}
      />

      <div className="container-3 p-24 md-flex-column gap-32 md-gap-32 md-w-fix">
        <div className={`w-30 flex flex-column mt-24 md-w-full pr-24 border-r-grey`}>
          <div className="gap-12 flex flex-column form-style-one mb-12">
            {!isManufacturer && !program.isSpecificURL && program.programIds.length > 1 && (
              <Select
                showSearch
                filterOption={filterOptions}
                placeholder="All Programs"
                id={'program'}
                allowClear
                mode="multiple"
                onChange={handleProgramFilter}
                value={program.selectedProgram}
              >
                {program.programOptions.length === 0 ? (
                  <Option key={'Loading...'} value={'Loading...'} disabled>
                    Loading...
                  </Option>
                ) : (
                  program.programOptions.map((option: ISelectOption) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))
                )}
              </Select>
            )}

            {location.locationIds.length === 1 && program.programIds.length === 1 && (
              <div className="flex flex-column p-12-0">
                <p className="info-desc m-0">Dealership</p>
                <h3 className="info-detail  gap-8 flex heading-xl" data-testid="h3-company-name">
                  {userData?.companyName}
                </h3>
              </div>
            )}
            {(location.locationIds.length > 1 || isManufacturer) && (
              <Select
                showSearch
                filterOption={filterOptions}
                placeholder="All Dealerships"
                id={'dealership'}
                allowClear
                mode="multiple"
                onChange={handleLocationFilter}
                value={location.selectedLocation}
              >
                {location.locationsOptions.length === 0 ? (
                  <Option key={'Loading...'} value={'Loading...'} disabled>
                    Loading...
                  </Option>
                ) : (
                  location.locationsOptions.map((option: ISelectOption) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))
                )}
              </Select>
            )}
          </div>

          <Total
            title="Total Rental Requests"
            totalSize={collectDataDashboard.totalRentalRequests}
            viewAllRedirect="/rentals/rental-requests"
            isLoading={collectDataDashboard.collectionDataLoading}
            addViewAll
          />
          <Total
            title="Invoice Total (INC. GST)"
            totalSize={collectDataDashboard.numberOutstandingTotal}
            viewAllRedirect="/rentals/rental-requests?filter=invoice-received"
            isLoading={collectDataDashboard.collectionDataLoading}
            isMoney
            addViewAll={rules.showInvoices}
          />
          <Total
            title="Total hires cost to date (EXC. GST)"
            totalSize={collectDataDashboard.totalDaysOnHire ? collectDataDashboard.totalDaysOnHire * 88.0 : 0}
            viewAllRedirect="/rentals/rental-requests?filter=on-hire"
            isLoading={collectDataDashboard.collectionDataLoading}
            isMoney
            smallVersion
          />

          <Total
            title="Total current hire days"
            totalSize={collectDataDashboard.totalDaysOnHire || 0}
            viewAllRedirect="/rentals/rental-requests"
            isLoading={collectDataDashboard.collectionDataLoading}
            smallVersion
          />
          <Total
            title="Total hire days invoiced"
            totalSize={collectDataDashboard.hireDaysInvoiced || 0}
            viewAllRedirect="/rentals/rental-requests?filter=invoice-received"
            isLoading={collectDataDashboard.collectionDataLoading}
            smallVersion
          />
        </div>
        <div className="w-70 grid grid-cols-3 gap-y-32 gap-x-8 mt-24 md-w-full md-grid-cols-2">
          <PercentagePieChart
            title={'Approved'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allStatusCount.Approved}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=approved"
            conicColors={{ '0%': '#5FE3C4', '50%': '#62CAC4', '100%': '#0D978F' }}
          />
          <PercentagePieChart
            title={'Pending'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allStatusCount.Pending}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=pending"
            conicColors={{ '0%': '#EDE176', '50%': '#FBCA4C', '100%': '#FFB800' }}
          />
          <PercentagePieChart
            title={'Scheduled'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allBookingCount['Scheduled']}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=scheduled"
            conicColors={{ '0%': '#FFBB82', '50%': '#FF9943', '100%': '#FF7500' }}
          />

          <PercentagePieChart
            title={'On Hire'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allBookingCount['In Progress']}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=on-hire"
            conicColors={{ '0%': '#60B0DF', '50%': '#1C9EEA', '100%': '#0072B5' }}
          />

          <PercentagePieChart
            title={'Completed'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allBookingCount['Completed']}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=completed"
            conicColors={{ '0%': '#75CB5D', '50%': '#41C51D', '100%': '#219600' }}
          />

          {rules.showInvoices && (
            <PercentagePieChart
              title={'Invoices Received'}
              total={collectDataDashboard.totalRentalRequests}
              isLoading={collectDataDashboard.collectionDataLoading}
              count={collectDataDashboard.invoicesReceived}
              link="/rentals/rental-requests?filter=invoice-received"
              conicColors={{ '0%': '#892CFF', '50%': '#9F1ECC', '100%': '#69008D' }}
            />
          )}

          <PercentagePieChart
            title={'Rejected'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allStatusCount.Rejected}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=rejected"
            conicColors={{ '0%': '#F96363', '50%': '#DB1515', '100%': '#990000' }}
          />

          <PercentagePieChart
            title={'No Bookings'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.allBookingCount['No booking']}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=no-booking"
            conicColors={{ '0%': '#8891A7', '50%': '#6B7895', '100%': '#3E4962' }}
          />

          <PercentagePieChart
            title={'Extension Outstanding'}
            total={collectDataDashboard.totalRentalRequests}
            count={collectDataDashboard.numberReachingDayLimit}
            isLoading={collectDataDashboard.collectionDataLoading}
            link="/rentals/rental-requests?filter=extension-outstanding"
            conicColors={{ '0%': '#FF2CEA', '50%': '#FF20C1', '100%': '#B20081' }}
          />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
