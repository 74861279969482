import { Auth } from 'aws-amplify';
import axios from 'axios';
import { IUser } from '@scanny-app/loopy-loop';

interface AuthResponse {
  userData: IUser | null;
  programIds: string[];
  locationIds: string[];
  error?: string;
}

export const getUserAuthData = async (
  loadPrograms: () => Promise<void>,
  getLocations: () => Promise<void>,
): Promise<AuthResponse> => {
  try {
    const currentSession = await Auth.currentSession();
    const token = currentSession.getAccessToken().getJwtToken();
    const idTokenPayload = currentSession.getIdToken().payload;

    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/getUserCompany`, JSON.stringify({}), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data.length === 0) {
      return {
        userData: null,
        programIds: [],
        locationIds: [],
      };
    }

    await Promise.all([loadPrograms(), getLocations()]);

    const userData: IUser = {
      USER_TYPE: 'External',
      firstName: idTokenPayload.given_name,
      lastName: idTokenPayload.family_name,
      email: idTokenPayload.email,
      portalAccess: response.data[0].portalAccess,
      profileType: response.data[0].portalAccountType,
      portalServices: response.data[0].portalServices.split(';'),
      currentCompany: response.data[0].id,
      companyName: response.data[0].name,
    };

    const programIds = idTokenPayload['custom:PROGRAM_IDS']?.split(',') || [];
    const locationIds = idTokenPayload['custom:LOCATION_IDS']?.split(',') || [];

    return {
      userData,
      programIds,
      locationIds,
    };
  } catch (error) {
    return {
      userData: null,
      programIds: [],
      locationIds: [],
      error: 'Failed to fetch user authentication data',
    };
  }
};
