import { IProgram, ISelectOption } from '@scanny-app/loopy-loop';
import axios from 'axios';
import { Auth } from 'aws-amplify';

export const fetchPrograms = async (): Promise<{
  programData: IProgram[];
  programOptions: ISelectOption[];
  error?: string;
}> => {
  try {
    const data = await Auth.currentSession();
    const token = data.getAccessToken().getJwtToken();

    const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Program`, JSON.stringify({}), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      const programData: IProgram[] = response.data;
      const programOptions = programData.map((program) => ({
        label: program.name,
        value: program.id,
      }));

      return { programData, programOptions };
    }

    return {
      programData: [],
      programOptions: [],
      error: 'Failed to fetch programs',
    };
  } catch (error) {
    return {
      programData: [],
      programOptions: [],
      error: 'Failed to fetch programs',
    };
  }
};
