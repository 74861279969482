import { Form, Input, Select, message, Row, Col, DatePicker } from 'antd';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import React, { useState, useContext, useEffect } from 'react';
import ApprovalVOR from '../../components/Modals/ApprovalVOR';
import {
  GetOptionsFromDB,
  ISelectOption,
  disablePastDatesBasedOnTime,
  disablePastDatesBasedOnTimeAndWeekend,
  filterOptions,
  getFormattedTimeZone,
  LargeLoading,
  SecondaryNavigation,
  capitalizeWords,
  UserContext,
  DealershipContext,
  IProgram,
} from '@scanny-app/loopy-loop';
interface CreateRentalRequestProps {
  setReloadDataDashboard: (value: boolean) => void;
}

function CreateRentalRequest({ setReloadDataDashboard }: CreateRentalRequestProps) {
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [componentDisabled, setComponentDisabled] = useState<boolean>(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [submissionLoadingBooking, setSubmissionLoadingBooking] = useState(false);
  const [requestType, setRequestType] = useState<string>('');
  const [bookingType, setBookingType] = useState<string>('');
  const [makeOptions, setMakeOptions] = useState<ISelectOption[]>([]);
  const [modelOptions, setModelOptions] = useState<ISelectOption[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<IProgram>({} as IProgram);
  const { program, location } = useContext(DealershipContext);
  const [vehicleOptions, setVehicleOptions] = useState<ISelectOption[]>([]);
  const userData = useContext(UserContext);
  const [vehicleModel, setVehicleModel] = useState<ISelectOption>({} as ISelectOption);
  const [modelDisabled, setModelDisabled] = useState(true);
  const [vehicleMake, setVehicleMake] = useState<ISelectOption>({} as ISelectOption);
  const [viewApprovalVORModalVisible, setViewApprovalVORModalVisible] = useState(false);
  const isNonVOR = requestType === 'Not VOR Related';

  const onChangeProgram = (selectedId: string) => {
    const programData = program.programData.find((program) => program.id === selectedId);
    if (programData) {
      setSelectedProgram(programData);
      handleMakeChange('', { label: programData.makeName, value: programData.makeID });
      setModelDisabled(false);
    } else {
      setSelectedProgram({} as IProgram);
      handleMakeChange('', { label: '', value: '' });
      setModelDisabled(true);
    }
    handleTypeChange('');
    form.setFieldsValue({
      ...form.getFieldsValue(),
      requestType: '',
    });
  };
  const onChangeLocation = () => {
    setVehicleOptions([]);
  };
  const getModels = async () => {
    if (vehicleMake.value !== '') {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Model`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: { make: vehicleMake.value },
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setModelOptions(sortedOptions);
    }
  };
  const getMakes = async () => {
    if (makeOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Make`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: {},
        },
        (item) => item.name,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setMakeOptions(sortedOptions);
    }
  };

  const getVehicle = async () => {
    let locationID = form.getFieldsValue().submittedByCompany;
    if (location.locationIds) {
      if (location.locationIds.length === 1) {
        locationID = location.locationIds[0];
      }
    }
    if (vehicleOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/EmbeddedCars`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: { locationID: locationID, makeID: selectedProgram.makeID },
        },
        (item) => `${item.makeName} ${item.modelName} - ${item.name}`,
      );

      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setVehicleOptions(
        sortedOptions.length === 0 ? [{ value: 'noVehiclesFound', label: 'No vehicles found', disabled: true }] : sortedOptions,
      );
    }
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMakeChange = (value: string, option: any) => {
    setModelDisabled(false);
    setModelOptions([]);
    setVehicleMake({
      ...vehicleMake,
      label: option.children,
      value: option.value,
    });
    setVehicleModel({} as ISelectOption);
    form.setFieldsValue({ modelId: undefined });
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleModelChange = (value: string, option: any) => {
    setVehicleModel({
      ...vehicleModel,
      label: option.children,
      value: option.value,
    });
  };
  const handleTypeChange = (value: string) => {
    setRequestType(value);
    if (value !== 'I have a VOR Number') {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        VORNumber: '',
        bookingStartDate: '',
        estimatedFinishDate: '',
        bookingType: '',
      });
    }
  };

  const handleBookingTypeChange = (value: string) => {
    setBookingType(value);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      bookingStartDate: '',
      selectVehicle: '',
    });
  };

  const handleSubmit = async () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      makeId: vehicleMake.value,
      modelId: vehicleModel.value,
    });
    try {
      await form.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      form.setFieldsValue({
        ...form.getFieldsValue(),
        makeId: null,
      });

      return;
    }

    setComponentDisabled(true);

    if (form.getFieldsValue().requestType === 'I have a VOR Number') {
      setSubmissionLoadingBooking(true);
    } else {
      setSubmissionLoading(true);
    }

    let bookingStartString = null;
    if (form.getFieldsValue().bookingStartDate) {
      bookingStartString = `${form
        .getFieldsValue()
        .bookingStartDate.format('YYYY-MM-DD')}T16:59:00.000000${getFormattedTimeZone()}`;
    }

    let estimatedFinishDateString = null;
    if (form.getFieldsValue().estimatedFinishDate) {
      estimatedFinishDateString = form.getFieldsValue().estimatedFinishDate.format('YYYY-MM-DD');
    }

    const sessionData = await Auth.currentSession();
    const token = sessionData.getAccessToken().getJwtToken();

    const formValues = form.getFieldsValue();
    const formData = {
      ...formValues,
      customerFirstName: capitalizeWords(formValues.customerFirstName),
      customerLastName: capitalizeWords(formValues.customerLastName),
      typeId: process.env.REACT_APP_RECORD_TYPE_ID,

      bookingStart: bookingStartString,
      estimatedFinishDate: estimatedFinishDateString,
      status: formValues.requestType === 'I have a VOR Number' ? 'Approved' : 'Pending',
      embeddedVehicleId: formValues.selectVehicle,
      submittedByName: `${userData?.firstName} ${userData?.lastName}`,
      submittedByEmail: userData?.email,

      VORNumber: formValues.VORNumber && formValues.VORNumber.toUpperCase(),
      vehicleVIN: formValues.vehicleVIN && formValues.vehicleVIN.toUpperCase(),
      vehicleRego: formValues.vehicleRego && formValues.vehicleRego.toUpperCase(),
      repairOrderNumber: formValues.repairOrderNumber && formValues.repairOrderNumber.toUpperCase(),
      purchaseOrderNumber: formValues.purchaseOrderNumber && formValues.purchaseOrderNumber.toUpperCase(),
    };

    if (program.programIds && location.locationIds) {
      if (location.locationIds.length === 1) {
        formData.submittedByCompany = location.locationIds[0];
      }

      /** If user access the specific URL or user account has one programIds pass the id program into submittedToCompany **/
      if (program.isSpecificURL) {
        formData.submittedToCompany = program.selectedProgram[0];
      } else if (program.programIds.length === 1) {
        formData.submittedToCompany = program.programIds[0];
      }
    }

    if (bookingStartString && form.getFieldsValue().comments) {
      formData.bookingComments = form.getFieldsValue().comments.substring(0, 200) + '...';
    }
    delete formData.bookingType;
    delete formData.selectVehicle;
    delete formData.makeName;
    delete formData.modelName;
    delete formData.bookingStartTime;
    delete formData.bookingStartDate;

    axios
      .post(`${process.env.REACT_APP_ENDPOINT_URL}/create/RentalRequest`, JSON.stringify(formData), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        message.success('Rental request created successfully');
        setSubmissionLoading(false);
        setSubmissionLoadingBooking(false);
        setReloadDataDashboard(true);
        setTimeout(() => {
          navigate('/rentals/rental-requests');
        }, 500);
      })
      .catch(() => {
        message.error('Error creating rental request');
        setSubmissionLoading(false);
        setSubmissionLoadingBooking(false);
        setComponentDisabled(false);
      });
  };

  const handleCheckVOR = async () => {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      makeId: vehicleMake.value,
      modelId: vehicleModel.value,
    });
    try {
      await form.validateFields();
    } catch (errorInfo) {
      form.setFieldsValue({
        ...form.getFieldsValue(),
        makeId: null,
      });

      message.error('Please review field errors');
      return;
    }

    setViewApprovalVORModalVisible(true);
  };

  const rightNode = (
    <>
      <button onClick={isNonVOR ? handleCheckVOR : handleSubmit} disabled={componentDisabled} className="btn-submit align-middle">
        Submit
      </button>
    </>
  );

  const handleModalCancel = () => {
    setViewApprovalVORModalVisible(false);
  };

  const onBack = () => navigate('/rentals/rental-requests');

  /** If user access the specific URL or user account has one programIds **/
  useEffect(() => {
    if (program.isSpecificURL) {
      onChangeProgram(program.selectedProgram[0]);
    } else if (program.programIds.length === 1) {
      onChangeProgram(program.programIds[0]);
    }
  }, [program.programData]);

  return (
    <>
      {submissionLoadingBooking && <LargeLoading customText="One moment, we are making the booking..." />}
      {submissionLoading && <LargeLoading />}

      <SecondaryNavigation menuTitle={'New Rental Request'} rightNode={rightNode} onBack={onBack} />
      <div className="container-2 p-24 md-w-fix">
        <Form className="w-full form-style-one" layout="vertical" form={form} disabled={componentDisabled}>
          <Row gutter={[16, 16]}>
            {(location.locationIds.length > 1 || program.programIds.length > 1) && (
              <Col span={24}>
                <h5 className="m-0 text-sm">Request Details</h5>
              </Col>
            )}
            {location.locationIds.length > 1 && (
              <Col
                lg={program.isSpecificURL || (location.locationIds.length > 1 && program.programIds.length === 1) ? 24 : 12}
                xs={24}
              >
                <Form.Item
                  label="Dealership"
                  name="submittedByCompany"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the dealership location',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={filterOptions}
                    placeholder="Select the dealership"
                    allowClear
                    onChange={onChangeLocation}
                  >
                    {location.locationsOptions.length === 0 ? (
                      <Option key={'Loading...'} value={'Loading...'} disabled>
                        Loading...
                      </Option>
                    ) : (
                      location.locationsOptions.map((option: ISelectOption) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}

            {!program.isSpecificURL && program.programIds.length > 1 && (
              <Col
                lg={!program.isSpecificURL && program.programIds.length > 1 && location.locationIds.length <= 1 ? 24 : 12}
                xs={24}
              >
                <Form.Item
                  label="Program"
                  name="submittedToCompany"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the program',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={filterOptions}
                    placeholder="Select the program"
                    allowClear
                    onChange={onChangeProgram}
                  >
                    {program.programOptions.length === 0 ? (
                      <Option key={'Loading...'} value={'Loading...'} disabled>
                        Loading...
                      </Option>
                    ) : (
                      program.programOptions.map((option: ISelectOption) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))
                    )}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <h5 className="m-0 text-sm">Customer Details</h5>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerFirstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the customer first name',
                  },
                ]}
              >
                <Input className="capitalize" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerLastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the customer last name',
                  },
                ]}
              >
                <Input className="capitalize" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerContactNumber"
                label="Contact Number"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the customer contact number',
                  },
                  {
                    pattern: /^\(?(?:\+?61|0)4\)?[ -]?[0-9]{7}[0-9]$/,
                    message: 'Please enter a valid phone number',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="customerEmail"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: 'Please enter a valid customer email',
                    type: 'email',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="vehicleRego"
                label="Car Registration"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the car registration',
                  },
                ]}
              >
                <Input className="uppercase" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="vehicleVIN"
                label="VIN"
                rules={[
                  { required: true, message: 'Please enter the VIN' },
                  {
                    pattern: /^[\w\d]{17}$/,
                    message: 'Please verify VIN is 17 characters without spaces or special characters',
                  },
                ]}
              >
                <Input maxLength={17} placeholder="KNAJP1A59C1T98076" className="uppercase" />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                name="makeId"
                label="Car Make"
                rules={[{ required: true, message: 'Please enter the car make' }]}
                style={{ position: 'relative' }}
              >
                <Select
                  disabled={true}
                  showSearch
                  filterOption={filterOptions}
                  onFocus={getMakes}
                  onClick={getMakes}
                  onChange={handleMakeChange}
                >
                  {makeOptions.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    makeOptions.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
              <div
                id="carMake"
                style={{
                  position: 'absolute',
                  top: '40px',
                  left: '20px',
                  bottom: '10px',
                  pointerEvents: 'none', // to make sure clicks go to the select
                }}
              >
                {selectedProgram.makeName}
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item name="modelId" label="Car Model" rules={[{ required: true, message: 'Please enter the car model' }]}>
                <Select
                  showSearch
                  filterOption={filterOptions}
                  onClick={getModels}
                  onFocus={getModels}
                  onChange={handleModelChange}
                  disabled={modelDisabled}
                >
                  {modelOptions.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    modelOptions.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
            {selectedProgram.id && (
              <>
                <Col span={24}>
                  <h5 className="mt-8 mb-0 text-sm">Booking</h5>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item name="repairOrderNumber" label="Repair Order Number">
                    <Input className="uppercase" />
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    name="purchaseOrderNumber"
                    label="Purchase Order Number"
                    rules={
                      selectedProgram.isPurchaseOrderMandatory
                        ? [
                            {
                              required: true,
                              message: 'Please enter the purchase order number',
                            },
                          ]
                        : [{ required: false }]
                    }
                  >
                    <Input className="uppercase" />
                  </Form.Item>
                </Col>
                {selectedProgram.VORProcess && (
                  <Col lg={12} xs={24}>
                    <Form.Item
                      name="requestType"
                      label="Request Type"
                      rules={[{ required: true, message: 'Please enter the request type' }]}
                    >
                      <Select onChange={handleTypeChange}>
                        <Option value="I have a VOR Number">I have a VOR Number</Option>
                        <Option value="Not VOR Related">Not VOR Related</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {requestType === 'I have a VOR Number' && (
                  <>
                    <Col lg={12} xs={24}>
                      <Form.Item
                        name="VORNumber"
                        label="VOR Number"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the VOR number',
                          },
                          {
                            pattern: /\d{6}[A-Za-z]\d{3}/,
                            message: 'Please verify VOR number is 10 characters (format: 6 digits, 1 letter, 3 digits)',
                          },
                        ]}
                      >
                        <Input maxLength={10} className="uppercase" />
                      </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                      <Form.Item
                        name="bookingType"
                        label="Booking Type"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the booking start date',
                          },
                        ]}
                      >
                        <Select onChange={handleBookingTypeChange}>
                          <Option value="Vehicle On Site">Vehicle On Site</Option>
                          <Option value="Vehicle Delivered">Vehicle Delivered</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {bookingType === 'Vehicle On Site' && requestType === 'I have a VOR Number' && (
                      <Col lg={12} xs={24}>
                        <Form.Item
                          name="selectVehicle"
                          label="Select Vehicle"
                          rules={[{ required: true, message: 'Please select the vehicle' }]}
                        >
                          <Select filterOption={filterOptions} showSearch onFocus={getVehicle} onClick={getVehicle}>
                            {vehicleOptions.length === 0 ? (
                              <Option key={'Loading...'} value={'Loading...'} disabled>
                                Loading...
                              </Option>
                            ) : (
                              vehicleOptions.map((option: ISelectOption) => (
                                <Option key={option.value} value={option.value} disabled={option?.disabled}>
                                  {option.label}
                                </Option>
                              ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                    )}

                    <Col lg={12} xs={24}>
                      <Form.Item
                        name="bookingStartDate"
                        label="Booking Start Date"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter the booking start date',
                          },
                        ]}
                      >
                        <DatePicker
                          disabled={requestType !== 'I have a VOR Number' || bookingType === ''}
                          className="box"
                          format="DD/MM/YYYY"
                          placeholder="Select date"
                          disabledDate={
                            bookingType === 'Vehicle Delivered'
                              ? disablePastDatesBasedOnTimeAndWeekend
                              : disablePastDatesBasedOnTime
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={12} xs={24}>
                      <Form.Item name="estimatedFinishDate" label="Estimated Finish Date">
                        <DatePicker
                          className="box"
                          format="DD/MM/YYYY"
                          placeholder="Select date"
                          disabledDate={
                            bookingType === 'Vehicle Delivered'
                              ? disablePastDatesBasedOnTimeAndWeekend
                              : disablePastDatesBasedOnTime
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                <Col lg={12} xs={24}>
                  <Form.Item
                    name="hotlineNumber"
                    label={selectedProgram.hotlineNumberName || 'Hotline Number'}
                    rules={
                      selectedProgram.hotlineNumberMandatory
                        ? [
                            {
                              required: true,
                              message: 'Please enter hotline number',
                            },
                          ]
                        : [{ required: false }]
                    }
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={24}>
              <Form.Item
                name="comments"
                label={'Comments'}
                rules={
                  isNonVOR
                    ? [
                        {
                          required: true,
                          message: 'Please enter comments',
                        },
                      ]
                    : [{ required: false }]
                }
              >
                <Input.TextArea rows={4} />
              </Form.Item>
              {isNonVOR && (
                <div className={'flex flex-column'}>
                  <div className={'text-xs mt-12'}>
                    To assist in approving your request please supply a detailed reason why your dealership can not supply a VOR
                    number at this time.
                  </div>
                  <div className={'text-xs mb-8 italic text-grey pl-12 my-12 border-l-grey'}>
                    Example: We cannot supply a VOR number at this time as the clients vehicle has a major oil leak coming from
                    the transmission that is visible and currently towed in undrivable, diagnosis is to be completed first and
                    hotline raised, we need to get client mobile asap the vehicle is 12 months old.
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </div>
      <ApprovalVOR
        open={viewApprovalVORModalVisible}
        onCancel={handleModalCancel}
        onOk={handleSubmit}
        accountName={selectedProgram.name}
      />
    </>
  );
}

export default CreateRentalRequest;
