import { Modal, Form, Row, Col, Select, Input, message } from 'antd';
import {
  getFormattedTimeZone,
  filterOptions,
  ISelectOption,
  GetOptionsFromDB,
  disablePastDatesBasedOnTime,
  disablePastDatesBasedOnTimeAndWeekend,
  UserContext,
} from '@scanny-app/loopy-loop';
import { DatePicker } from 'antd';
import { useState, useContext } from 'react';
export interface IExchangeForm {
  reasonForExchange: string;
  startDate: string;
  estimatedFinishDate: string | null;
  selectVehicle: string;
  jobNotes: string;
  bookingId: string;
  jobAddress: string | undefined;
}
interface ExchangeCarProps {
  open: boolean;
  onCancel: () => void;
  bookingId: string;
  isLoading: boolean;
  onExchangeSubmit: (values: IExchangeForm) => void;
  /** The ID of the location that will be used in the embedded vehicles query**/
  locationID?: string;
}
function ExchangeCar({ open, onCancel, bookingId, onExchangeSubmit, isLoading, locationID }: ExchangeCarProps) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [bookingType, setBookingType] = useState<string>('');
  const [vehicleOptions, setVehicleOptions] = useState<ISelectOption[]>([]);
  const userData = useContext(UserContext);
  const handleBookingTypeChange = (value: string) => {
    setBookingType(value);
    form.setFieldsValue({ startDate: '', selectVehicle: '' });
  };

  const getVehicle = async () => {
    if (vehicleOptions.length === 0) {
      const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/EmbeddedCars`;
      const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
        {
          endpoint: url,
          filter: { ...(locationID ? { locationID: locationID } : {}) },
        },
        (item) => `${item.makeName} ${item.modelName} - ${item.name}`,
      );
      const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
      setVehicleOptions(
        sortedOptions.length === 0 ? [{ value: 'noVehiclesFound', label: 'No vehicles found', disabled: true }] : sortedOptions,
      );
    }
  };
  const handleOK = () => {
    form
      .validateFields()
      .then((values) => {
        const formattedValues = {
          selectVehicle: values.selectVehicle,
          startDate: `${values.startDate.format('YYYY-MM-DD')}T16:59:00.000000${getFormattedTimeZone()}`,
          estimatedFinishDate: values.estimatedFinishDate
            ? `${values.estimatedFinishDate.format('YYYY-MM-DD')}T16:59:00.000000${getFormattedTimeZone()}`
            : null,
          reasonForExchange: values.reasonForExchange,
          jobNotes: `${process.env.REACT_APP_MANUFACTURER_MAKE_NAME?.toUpperCase()} LOAN HIRE`,
          bookingId: bookingId,
          jobAddress: userData?.companyName,
        };
        onExchangeSubmit(formattedValues);
      })
      .catch(() => {
        message.error('Please review field errors');
      });
  };
  return (
    <Modal
      className="custom-modal left-menu"
      width={400}
      centered
      okText="Submit"
      cancelText="Cancel"
      title={'Exchange vehicle'}
      open={open}
      onCancel={onCancel}
      onOk={handleOK}
      okButtonProps={{ disabled: isLoading }}
    >
      <Form disabled={isLoading} form={form} className="w-full form-style-one" layout="vertical">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <Form.Item
              name="bookingType"
              label="Booking Type"
              rules={[{ required: true, message: 'Please select the booking type' }]}
            >
              <Select onChange={handleBookingTypeChange}>
                <Option value="Vehicle On Site">Vehicle On Site</Option>
                <Option value="Vehicle Delivered">Vehicle Delivered</Option>
              </Select>
            </Form.Item>
          </Col>

          {bookingType === 'Vehicle On Site' && (
            <Col span={24}>
              <Form.Item
                name="selectVehicle"
                label="Select Vehicle"
                rules={[{ required: true, message: 'Please select the vehicle' }]}
              >
                <Select filterOption={filterOptions} showSearch onFocus={getVehicle} onClick={getVehicle}>
                  {vehicleOptions.length === 0 ? (
                    <Option key={'Loading...'} value={'Loading...'} disabled>
                      Loading...
                    </Option>
                  ) : (
                    vehicleOptions.map((option: ISelectOption) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))
                  )}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              name="startDate"
              label="Start Date"
              rules={[
                {
                  required: true,
                  message: 'Please select a date',
                },
              ]}
            >
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select date"
                disabled={bookingType == ''}
                disabledDate={
                  bookingType === 'Vehicle Delivered' ? disablePastDatesBasedOnTimeAndWeekend : disablePastDatesBasedOnTime
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="estimatedFinishDate" label="Estimated Finish Date">
              <DatePicker
                className="box"
                format="DD/MM/YYYY"
                placeholder="Select date"
                disabledDate={
                  bookingType === 'Vehicle Delivered' ? disablePastDatesBasedOnTimeAndWeekend : disablePastDatesBasedOnTime
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="reasonForExchange" label="Reason for exchange">
              <TextArea style={{ minHeight: 100 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ExchangeCar;
