import { Modal, Form, Col, Input, Row, Select, message } from 'antd';
import { useContext, useState } from 'react';
import { ISelectOption } from '../../interfaces/SelectOption';
import { LargeLoading } from '../../icons/CarLoading/MyLoading';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { UserContext } from '../../interfaces/User';
import { logger, ILog } from '@scanny-app/loopy-loop';

interface EditFieldProps {
  open: boolean;
  onCancel: () => void;
}

function PopupFeedback({ open, onCancel }: EditFieldProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { Option } = Select;
  const userData = useContext(UserContext);

  const reasonOptions = [
    { value: 'Feedback', label: 'Feedback' },
    { value: 'Feature Request', label: 'Feature Request' },
    { value: 'Help', label: 'Help' },
    { value: 'Other', label: 'Other' },
  ];

  const onSubmit = async () => {
    if (!userData) {
      message.error('User data is not available');
      return;
    }

    try {
      await form.validateFields();
    } catch (errorInfo) {
      message.error('Please review field errors');
      return;
    }
    setIsLoading(true);

    const formatValues = {
      sender: userData.email,
      recipients: 'cruize@carbiz.com.au',
      subject: form.getFieldValue('reasonFeedback:'),
      content: form.getFieldValue('commentFeedback'),
    };

    try {
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();

      const response = await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/send-mail`, JSON.stringify(formatValues), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      logger.push({
        email: userData?.email,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        sourceURL: window.location.href,
        companySfID: userData?.currentCompany,
        companyName: userData?.companyName,
        requestType: 'POST',
        endpoint: `${process.env.REACT_APP_ENDPOINT_URL}/send-mail`,
        status: `${response.status}`,
        description: 'feedback submission',
        environment: process.env.REACT_APP_NODE_ENV || 'production',
      } as ILog);

      setIsLoading(false);
      onCancel();
      form.resetFields();

      message.success('Feedback sent successfully');
    } catch (error) {
      setIsLoading(false);
      message.error(`Error sent the feedback: ${error}`);
    }
  };

  if (isLoading) {
    return <LargeLoading customText="Just a moment, we're submitting the Feedback...." />;
  }

  return (
    <Modal
      className="custom-modal left-menu"
      width={360}
      centered
      okText="Submit"
      cancelText="Cancel"
      open={open}
      onCancel={onCancel}
      onOk={onSubmit}
      okButtonProps={{ disabled: isLoading }}
    >
      <Form disabled={isLoading} form={form} className="w-full form-style-one" layout="vertical">
        <Row gutter={[16, 12]}>
          <Col span={24}>
            <h6 className="m-0 text-sm font-semibold">Feedback</h6>
          </Col>
          <Col span={24}>
            <Form.Item
              name="reasonFeedback"
              id="reasonFeedback"
              label="Reason for feedback"
              rules={[{ required: true, message: 'Please select the option' }]}
            >
              <Select placeholder="Select the reason">
                {reasonOptions.map((option: ISelectOption) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="commentFeedback" label="Comment">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default PopupFeedback;
