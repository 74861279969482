import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { MyIcon } from '@scanny-app/loopy-loop';

function AccessDenied() {
  const navigate = useNavigate();
  function handleLogout() {
    Auth.signOut().then(() => {
      navigate(0);
    });
  }
  return (
    <section id="notfound-section">
      <MyIcon icon="IconConfusedCar" fill="none" />
      <div className="flex gap-24 flex-column middle my-40 md-px-20">
        <h1 className="text-black-80 font-extrabold m-0 heading-xl md-heading-base text-center">Action required</h1>
        <p className="text-xl md-text-sm text-black-80 text-center m-0 w-60 md-w-full">
          You do not have access to this portal. Please contact us to request access
        </p>
      </div>

      <div className="flex gap-16 md-flex-column md-w-fix">
        <button
          className="btn-black-80 text-sm font-bold p-12-16 rounded-6"
          onClick={() => {
            window.open('https://www.carbiz.com.au/contact-us/', '_self');
          }}
        >
          Contact us
        </button>
        <button onClick={handleLogout} className="btn-outline-black-80 text-sm font-bold p-12-16 rounded-6">
          Log me out
        </button>
      </div>
    </section>
  );
}

export default AccessDenied;
