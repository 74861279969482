import type { ColumnsType } from 'antd/es/table';
import {
  downloadInvoicePDF,
  formatISO,
  BookingStatusComponent,
  ExtensionStatusComponent,
  IRentalRequest,
  MyIcon,
} from '@scanny-app/loopy-loop';
import { NearDayLimit } from '@scanny-app/loan-cars/src/helpers/NearDayLimit';
import { IProgramContext } from '@scanny-app/loopy-loop/src/interfaces/Dealership';

const getColumnKeys = (filterParam: string | null, profileType: string, isMobile: boolean) => {
  if (isMobile) {
    return ['mobileCols', 'mobileOperation'];
  }
  const columnsKeys: string[] = ['customerName', 'vehicleVIN', 'operation'];

  if (profileType === 'Dealer') {
    columnsKeys.push('repairOrderNumber', 'submittedToCompanyName');
  }
  if (filterParam === 'invoice-received') {
    columnsKeys.push('invoiceNumber', 'invoiceAmount', 'exportInvoice', 'invoiceDate');
    return columnsKeys;
  } else if (filterParam === 'extension-outstanding') {
    columnsKeys.push('totalDays', 'requestExtension');
  } else {
    columnsKeys.push('status', 'rentalCar', 'customerCar', 'customerContactNumber', 'VORNumber');
  }
  columnsKeys.push(
    'submittedByCompanyId',
    'bookingStatus',
    'bookingStart',
    'bookingFinish',
    'vehicleRego',
    'carMake',
    'carModel',
    'CreatedDate',
  );
  return columnsKeys;
};

const RENTAL_REQUEST_DATA_COLUMNS = (
  filterParam: string | null,
  navigate: (path: string) => void,
  isMobile: boolean,
  profileType: string,
  program: IProgramContext,
): ColumnsType<IRentalRequest> => {
  const columnKeys: string[] = getColumnKeys(filterParam, profileType, isMobile);

  const allColumns: ColumnsType<IRentalRequest> = [
    {
      title: 'Repair Order',
      dataIndex: 'repairOrderNumber',
      key: 'repairOrderNumber',
      width: 120,
      fixed: 'left',
      render: (text, record) => <span>{record.repairOrderNumber === null ? '-' : record.repairOrderNumber}</span>,
    },

    {
      title: 'Customer',
      key: 'customerName',
      width: 120,
      fixed: 'left',
      render: (text, record) => (
        <div
          onClick={() =>
            navigate(
              filterParam !== null || filterParam === ''
                ? `/rentals/rental-requests/view/${record.id}?filter=${filterParam}`
                : `/rentals/rental-requests/view/${record.id}`,
            )
          }
          className="hover-pointer"
        >
          {record.customerFirstName} {record.customerLastName}
        </div>
      ),
      sorter: (a, b) => {
        const customerA = `${a.customerFirstName} ${a.customerLastName}`.toUpperCase(); // Convert names to uppercase for case-insensitive sorting
        const customerB = `${b.customerFirstName} ${b.customerLastName}`.toUpperCase();
        return customerA.localeCompare(customerB);
      },
    },
    {
      title: <div className="w-full content-center flex">Approval</div>,
      dataIndex: 'status',
      key: 'status',
      width: 90,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (text) => (
        <div className="w-full content-center flex">
          {text === 'Approved' ? (
            <MyIcon icon="IconApproved" fill="#28D94F" />
          ) : text === 'Pending' ? (
            <MyIcon icon="IconPending" fill="#4277FF" />
          ) : text === 'Rejected' ? (
            <MyIcon icon="IconDecline" fill="#FF4242" />
          ) : (
            text
          )}
        </div>
      ),
    },
    ...(program.programData.length === 1 && program.programData[0].VORProcess
      ? [
          {
            title: 'VOR Number',
            dataIndex: 'VORNumber',
            key: 'VORNumber',
            width: 120,
            render: (text: any, record: IRentalRequest) => <>{record.VORNumber ? record.VORNumber : '-'}</>,
          },
        ]
      : []),
    {
      title: 'Booking Status',
      dataIndex: 'bookingStatus',
      key: 'bookingStatus',
      width: 140,
      sorter: (a, b) => a.bookingStatus.localeCompare(b.bookingStatus),
      render: (text, record) => <>{record.status === 'Rejected' ? '-' : <BookingStatusComponent status={text} />}</>,
    },

    {
      title: 'Rental Car',
      dataIndex: 'rentalCar',
      key: 'rentalCar',
      width: 100,
      render: (text, record) => (
        <span>
          {record.hireVehicleModelName !== undefined ? `${record.hireVehicleMakeName} ${record.hireVehicleModelName}` : '-'}
        </span>
      ),
    },
    {
      title: 'Customer Car',
      dataIndex: 'customerCar',
      key: 'customerCar',
      width: 140,
      render: (text, record) => (
        <span>
          {record.makeName} {record.modelName}
        </span>
      ),
    },

    {
      title: 'Customer Car Rego',
      dataIndex: 'vehicleRego',
      key: 'vehicleRego',
      width: 150,
      render: (text, record) => <span>{record.vehicleRego.toLocaleUpperCase()}</span>,
    },
    {
      title: 'Booking Start',
      dataIndex: 'bookingStart',
      key: 'bookingStart',
      width: 130,
      render: (text, record) => (
        <span>{record.hireStartDate ? new Date(record.hireStartDate).toLocaleDateString('en-AU') : '-'}</span>
      ),
      sorter: (a, b, order) => {
        if (order === undefined) {
          return 0;
        }
        const dateNull = order === 'ascend' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
        const dateA = a.hireStartDate ? new Date(a.hireStartDate).getTime() : dateNull;
        const dateB = b.hireStartDate ? new Date(b.hireStartDate).getTime() : dateNull;
        return dateA - dateB;
      },
    },
    {
      title: 'Estimated Finish Date',
      dataIndex: 'estimatedFinishDate',
      key: 'estimatedFinishDate',
      width: 180,
      sorter: (a, b) => a.bookingStatus.localeCompare(b.bookingStatus),

      render: (text, record) => <>{record.finishDate ? new Date(record.finishDate).toLocaleDateString('en-AU') : '--'}</>,
    },
    {
      title: 'Booking Finish',
      dataIndex: 'bookingFinish',
      key: 'bookingFinish',
      width: 136,
      render: (text, record) => (
        <span>{record.hireFinishDate ? new Date(record.hireFinishDate).toLocaleDateString('en-AU') : '-'}</span>
      ),
      sorter: (a, b, order) => {
        if (order === undefined) {
          return 0;
        }
        const dateNull = order === 'ascend' ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
        const dateA = a.hireFinishDate ? new Date(a.hireFinishDate).getTime() : dateNull;
        const dateB = b.hireFinishDate ? new Date(b.hireFinishDate).getTime() : dateNull;
        return dateA - dateB;
      },
    },
    {
      title: 'Contact Number',
      dataIndex: 'customerContactNumber',
      key: 'customerContactNumber',
      width: 130,
      render: (text, record) => <span>{record.customerContactNumber}</span>,
    },
    {
      title: 'VIN',
      dataIndex: 'vehicleVIN',
      key: 'vehicleVIN',
      width: 186,
      render: (text, record) => <span>{record.vehicleVIN.toUpperCase()}</span>,
    },
    {
      title: 'Total Days',
      dataIndex: 'totalDays',
      key: 'totalDays',
      width: 110,
      render: (text, record) => <>{record.hireNumberOfDays}</>,
      sorter: (a, b) => a.hireNumberOfDays - b.hireNumberOfDays,
    },

    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      width: 140,
      render: (text, record) => <span>{record.hireInvoiceNumber}</span>,
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      width: 140,
      render: (text, record) => <span>{record.hireInvoiceAmount === undefined ? '-' : `$${record.hireInvoiceAmount}`}</span>,
    },
    {
      title: 'Export Invoice',
      dataIndex: 'exportInvoice',
      key: 'exportInvoice',
      width: 160,
      render: (text, record) => (
        <button
          onClick={() => downloadInvoicePDF(record.hireInvoice, record.hireInvoiceNumber)}
          className="font-semibold btn-text-blue flex middle content-center gap-8"
        >
          <MyIcon icon={'IconExport'} />
          Download PDF
        </button>
      ),
    },
    {
      title: 'Dealership',
      dataIndex: 'submittedByCompanyId',
      key: 'submittedByCompanyId',
      width: 140,
      sorter: (a, b) => a.submittedByCompanyName?.localeCompare(b.submittedByCompanyName || '') || 0,
      render: (text, record) => <span>{record.submittedByCompanyName}</span>,
    },
    {
      title: 'Program',
      dataIndex: 'submittedToCompanyName',
      key: 'submittedToCompanyName',
      width: 100,
      render: (text, record) => (
        <span>{record.submittedToCompanyName !== undefined ? `${record.submittedToCompanyName}` : '-'}</span>
      ),
    },
    {
      title: 'Invoice Date',
      dataIndex: 'invoiceDate',
      key: 'invoiceDate',
      width: 160,
      render: (text, record) => <span className="sort-date">{formatISO(record.hireInvoiceCreatedDate).toUpperCase()}</span>,
      sorter: (a, b) => new Date(a.hireInvoiceCreatedDate).getTime() - new Date(b.hireInvoiceCreatedDate).getTime(),
    },

    {
      title: 'Action',
      dataIndex: 'requestExtension',
      key: 'requestExtension',
      width: 180,
      render: (text, record) => {
        const extensionStatus = NearDayLimit(record, 'extensionStatus');
        const navigateUrl =
          filterParam !== null && filterParam !== ''
            ? `/rentals/rental-requests/view/${record.id}?filter=${filterParam}&approvals=true`
            : `/rentals/rental-requests/view/${record.id}&approvals=true`;

        const btnRequestExtension = (
          <button onClick={() => navigate(navigateUrl)} className="font-semibold btn-text-blue flex middle gap-8">
            <MyIcon icon={'IconPDF'} />
            {profileType === 'Manufacturer' ? 'Extension Request' : 'Request Extension'}
          </button>
        );

        if (extensionStatus === 'Pending') {
          return profileType === 'Manufacturer' ? btnRequestExtension : <ExtensionStatusComponent status={'Pending'} />;
        } else if (extensionStatus === 'Rejected') {
          return <ExtensionStatusComponent status={'Rejected'} />;
        } else {
          return profileType === 'Manufacturer' ? 'No request extension' : btnRequestExtension;
        }
      },
    },

    {
      title: 'Created Date',
      dataIndex: 'CreatedDate',
      key: 'CreatedDate',
      width: 160,
      render: (text, record) => <span className="sort-date">{formatISO(record.createdDate).toUpperCase()}</span>,
      sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
    },

    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 40,
      render: (_, record) => (
        <button
          onClick={() =>
            navigate(
              filterParam !== null || filterParam === ''
                ? `/rentals/rental-requests/view/${record.id}?filter=${filterParam}`
                : `/rentals/rental-requests/view/${record.id}`,
            )
          }
          className="btn-action-table content-center w-full h-full"
        >
          <MyIcon icon="IconNext" />
        </button>
      ),
    },
    {
      title: 'Display Data',
      key: 'mobileCols',
      defaultSortOrder: 'descend',
      sorter: (a, b) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
      render: (text, record) => (
        <>
          <div className="flex flex-row w-full content-space-between pb-12 mb-12 border-bottom-dashed-grey gap-16">
            <div className="w-30 font-semibold flex middle">
              {record.customerFirstName} {record.customerLastName}
            </div>
            <div className="w-30 flex middle content-center">{record.vehicleRego} </div>
            <div className="w-30 flex middle content-end">
              {' '}
              {record.status === 'Rejected' ? '-' : <BookingStatusComponent status={record.bookingStatus} />}
            </div>
          </div>
          <div className="flex flex-row w-full content-center gap-16">
            <div className="w-30 text-xxs text-grey flex middle">{record.customerContactNumber}</div>
            <div className="w-30 flex middle flex-row gap-8 text-xxs content-center text-grey">{record.status}</div>
            {filterParam === 'invoice-received' ? (
              <button
                onClick={() => downloadInvoicePDF(record.hireInvoice, record.hireInvoiceNumber)}
                className="font-semibold btn-text-blue flex middle content-center gap-8 md-text-xxs"
              >
                Download PDF
              </button>
            ) : (
              <div className="w-30 text-xxs text-grey flex middle content-end">{formatISO(record.createdDate).toUpperCase()}</div>
            )}
          </div>
        </>
      ),
    },
    {
      title: '',
      key: 'mobileOperation',
      fixed: 'right',
      width: 32,
      render: (_, record) => (
        <button
          onClick={() =>
            navigate(
              filterParam !== null || filterParam === ''
                ? `/rentals/rental-requests/view/${record.id}?filter=${filterParam}`
                : `/rentals/rental-requests/view/${record.id}`,
            )
          }
          className="btn-action-table content-center w-full h-full"
        >
          <MyIcon icon="IconNext" />
        </button>
      ),
    },
  ];
  return allColumns.filter((column) => column.key && columnKeys.includes(column.key as string));
};

export { RENTAL_REQUEST_DATA_COLUMNS };
