import dayjs from 'dayjs';
const disablePastDatesBasedOnTime = (currentDate: dayjs.Dayjs) => {
  const now = dayjs();
  const today = now.startOf('day');

  return currentDate.isBefore(today);
};

const disablePastDatesBasedOnTimeAndWeekend = (currentDate: dayjs.Dayjs) => {
  const now = dayjs();
  const today = now.startOf('day');
  const twoPM = today.add(14, 'hour');
  const isWeekend = currentDate.day() === 0 || currentDate.day() === 6; // 0 = Sunday, 6 = Saturday

  if (now.isBefore(twoPM)) {
    return currentDate.isBefore(today, 'day') || isWeekend;
  } else {
    return currentDate.isBefore(today.add(1, 'day'), 'day') || isWeekend;
  }
};

export { disablePastDatesBasedOnTime, disablePastDatesBasedOnTimeAndWeekend };
