import { useParams, useNavigate } from 'react-router-dom';
import { Input, Form, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { IRentalRequest, LargeLoading, SecondaryNavigation } from '@scanny-app/loopy-loop';

interface RequestStatusUpdateProps {
  type: 'Approve' | 'Reject';
  setReloadDataDashboard: (value: boolean) => void;
}

function RequestStatusUpdate({ type, setReloadDataDashboard }: RequestStatusUpdateProps) {
  const [rentalRequestData, setRentalRequestData] = useState<IRentalRequest>({} as IRentalRequest);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [submissionPending, setSubmissionPending] = useState(false);
  const [statusComments, setStatusComments] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        const token = data.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/v1/get/RentalRequest`, JSON.stringify({ id: id }), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setRentalRequestData(response.data[0]);
            setIsLoading(false);
          })
          .catch(() => {
            message.error('Error getting rental request');
          });
      })
      .catch(() => {
        message.error('Error getting rental request');
      });
  }, [id]);

  const handleSubmit = async () => {
    setSubmissionPending(true);
    try {
      const sessionData = await Auth.currentSession();
      const token = sessionData.getAccessToken().getJwtToken();
      const tableName = 'RentalRequest';
      const updatedStatus = type === 'Approve' ? 'Approved' : 'Rejected';

      const data = {
        status: updatedStatus,
        statusComments: form.getFieldValue('statusComments'),
      };

      await axios.post(`${process.env.REACT_APP_ENDPOINT_URL}/update/${tableName}/${id}`, JSON.stringify(data), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Rental request updated successfully');
      setReloadDataDashboard(true);

      setTimeout(() => {
        navigate('/rentals/rental-requests');
      }, 500);
    } catch (error) {
      message.error('Error updating rental request');
      setSubmissionPending(false);
    }
  };
  const buttonCondition =
    statusComments === null ||
    statusComments === '' ||
    submissionPending ||
    rentalRequestData.status === 'Approved' ||
    rentalRequestData.status === 'Rejected';

  const formDisabled = submissionPending || rentalRequestData.status === 'Approved' || rentalRequestData.status === 'Rejected';

  const rightNode = (
    <>
      <button
        className="delete-btn cust-btn"
        disabled={submissionPending}
        onClick={() => {
          navigate(`/rentals/rental-requests/view/${id}`);
        }}
      >
        Cancel
      </button>

      {type === 'Approve' ? (
        <button className="approve-btn cust-btn" onClick={() => setShowConfirmation(true)} disabled={buttonCondition}>
          {type}
        </button>
      ) : (
        <button className="reject-btn cust-btn" onClick={() => setShowConfirmation(true)} disabled={buttonCondition}>
          {type}
        </button>
      )}
    </>
  );

  const onBack = () => navigate('/rentals/rental-requests');

  return (
    <>
      {submissionPending && <LargeLoading />}
      <Modal open={showConfirmation} onCancel={() => setShowConfirmation(false)} onOk={handleSubmit} className="custom-modal">
        <p>Are you sure you want to submit?</p>
        <p>This will {type.toLowerCase()} this rental request and cannot be undone.</p>
      </Modal>

      <SecondaryNavigation menuTitle={`${type} Rental Request`} rightNode={rightNode} onBack={onBack} />
      {isLoading ? (
        <LargeLoading />
      ) : (
        <div className="container-2 p-24 md-w-fix gap-16">
          <div className="flex flex-column box-1 overflow-hidden" key={id}>
            <div className="p-16 content-space-between flex middle border-b bg-grey-1">
              <div className="text-sm font-semibold">
                {rentalRequestData.customerFirstName} {rentalRequestData.customerFirstName}
              </div>
            </div>
            <div className="p-16 flex flex-row md-flex-column text-xs gap-12">
              <div className="flex w-60 md-flex-row md-w-full gap-12">
                <div className="flex flex-column gap-8 w-50">
                  <div>VIN Number</div>
                  <div className="font-semibold">{rentalRequestData.vehicleVIN}</div>
                </div>
                <div className="flex flex-column gap-8 w-50">
                  <div>Car Registration</div>
                  <div className="font-semibold">{rentalRequestData.vehicleRego}</div>
                </div>
              </div>

              <div className="flex w-40 md-flex-row md-w-full gap-12">
                <div className="flex flex-column gap-8 w-50">
                  <div>Car Make</div>
                  <div className="font-semibold">{rentalRequestData.makeName}</div>
                </div>
                <div className="flex flex-column gap-8 w-50">
                  <div>Car Model</div>

                  <div className="font-semibold">{rentalRequestData.modelName}</div>
                </div>
              </div>
            </div>
            <div className="p-16 flex flex-column text-xs gap-8">
              <div>Comments</div>
              <div className="font-semibold">{rentalRequestData.comments ? rentalRequestData.comments : '---'}</div>
            </div>
          </div>

          <Form form={form} className="w-full form-style-one" layout="vertical">
            <Form.Item
              name="statusComments"
              label={`${type === 'Approve' ? 'Approval' : 'Rejection'} Comments`}
              rules={[{ required: true, message: 'This field cannot be blank' }]}
            >
              <Input.TextArea
                placeholder={
                  rentalRequestData.statusComments !== null
                    ? rentalRequestData.statusComments
                    : `Add ${type === 'Approve' ? 'approval' : 'rejection'} comments...`
                }
                value={statusComments}
                onChange={(e) => setStatusComments(e.target.value)}
                disabled={formDisabled}
              />
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
}
export default RequestStatusUpdate;
