import { createContext } from 'react';
import { ISelectOption } from './SelectOption';
import { IProgram } from './Program';

export interface IDealership {
  id: string;
  name: string;
}

export interface IProgramContext {
  programOptions: ISelectOption[];
  programData: IProgram[];
  programIds: string[];
  selectedProgram: string[];
  setSelectedProgram: (values: string[]) => void;
  isSpecificURL: boolean;
}

export interface ILocationContext {
  locationsOptions: ISelectOption[];
  locationIds: string[];
  selectedLocation: string[];
  setSelectedLocation: (values: string[]) => void;
}

export interface IRules {
  showInvoices: boolean;
}

interface DealershipContextProps {
  program: IProgramContext;
  location: ILocationContext;
  setReloadDashboard: (value: boolean) => void;
  rules: IRules;
}

export const DealershipContext = createContext<DealershipContextProps>({
  program: {
    programOptions: [],
    programData: [],
    programIds: [],
    selectedProgram: [],
    setSelectedProgram: () => {},
    isSpecificURL: false,
  },
  location: {
    locationsOptions: [],
    locationIds: [],
    selectedLocation: [],
    setSelectedLocation: () => {},
  },
  setReloadDashboard: () => {},
  rules: {
    showInvoices: false,
  },
});
