import { useState } from 'react';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { message } from 'antd';
import { LargeLoading, NewReturn, NewBooking, OverviewRentalAgreement } from '@scanny-app/loopy-loop';
import { OverviewData } from '../../pages/SinglePages/ViewRecord';
import OverviewHire from '../OverviewElement/OverviewHire';
import OverviewInvoice from '../OverviewElement/OverviewInvoice';
import OverviewApproval from '../OverviewElement/OverviewApproval';
import OverviewChecklist from '../OverviewElement/OverviewChecklist';
import OverviewReturnCar from '../OverviewElement/OverviewReturnCar';
import SequentialCard from '../Card/SequentialCard';
interface QueryBooking {
  overviewData: OverviewData;
}

interface BookingSubmit {
  bookingComments?: string;
  bookingStart?: string;
  estimatedFinishDate?: string | null;
  returnNotes?: string;
  finishDate?: string;
}

function Overview({
  overviewData: {
    rentalRequestData,
    bookingData,
    invoiceData,
    filterParam,
    setReloadDataDashboard,
    setReloadDataRentalRequest,
    setViewChecklistCar,
    onClickTab,
  },
}: QueryBooking) {
  const [viewMakeBookingModalVisible, setViewMakeBookingModalVisible] = useState(false);
  const [viewReturnModalVisible, setViewReturnModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isVORLoading, setIsVORLoading] = useState(false);
  const mostRecentBooking = bookingData[0];
  const needsRentalAgreement = mostRecentBooking && mostRecentBooking?.rentalAgreement === null;

  const handleModalCancel = () => {
    setViewMakeBookingModalVisible(false);
    setViewReturnModalVisible(false);
  };

  const handleBookingSubmit = (values: BookingSubmit) => {
    setIsLoading(true);
    updateBookingStatus(rentalRequestData.id, values);
  };

  const updateBookingStatus = (id: string, dataToSubmit: BookingSubmit) => {
    Auth.currentSession()
      .then((data) => {
        const token = data.getAccessToken().getJwtToken();
        axios
          .post(`${process.env.REACT_APP_ENDPOINT_URL}/update/RentalRequest/${id}`, JSON.stringify(dataToSubmit), {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            setIsLoading(false);
            setReloadDataRentalRequest(true);
            setReloadDataDashboard(true);

            message.success('Booking updated successfully');
            setViewMakeBookingModalVisible(false);
            setViewReturnModalVisible(false);
          })
          .catch(() => {
            message.error('Something went wrong. Please try again later.');
            setIsLoading(false);
          });
      })
      .catch(() => {
        message.error('Something went wrong. Please try again later.');
        setIsLoading(false);
      });
  };
  const navigateURL: string =
    filterParam !== '' && filterParam !== null
      ? `/rentals/rental-agreement/${rentalRequestData.id}/${rentalRequestData.hire}?filter=${filterParam}`
      : `/rentals/rental-agreement/${rentalRequestData.id}/${rentalRequestData.hire}`;
  return (
    <>
      <NewBooking
        onCancel={handleModalCancel}
        open={viewMakeBookingModalVisible}
        onBookingSubmit={handleBookingSubmit}
        locationID={rentalRequestData.submittedByCompanyId}
      />
      <NewReturn onCancel={handleModalCancel} open={viewReturnModalVisible} onReturnSubmit={handleBookingSubmit} />

      {isVORLoading ? (
        <LargeLoading customText="Just a moment, we're updating the VOR Number...." />
      ) : isLoading ? (
        <LargeLoading customText="Just a moment, we're saving the booking..." />
      ) : null}

      <div className="flex gap-24 flex-column">
        <SequentialCard
          title={'1. Approval Request'}
          content={
            <OverviewApproval
              rentalRequestData={rentalRequestData}
              onClickTab={onClickTab}
              VORPass={[setReloadDataDashboard, setReloadDataRentalRequest, setIsVORLoading]}
            />
          }
        />

        <SequentialCard
          title={'2. Hire Details'}
          content={
            <OverviewHire
              rentalRequestData={rentalRequestData}
              mostRecentBooking={mostRecentBooking}
              setReloadDataRentalRequest={setReloadDataRentalRequest}
              onClickTab={onClickTab}
              setViewMakeBookingModalVisible={setViewMakeBookingModalVisible}
            />
          }
        />

        <SequentialCard
          onlyVisible={'Manufacturer'}
          title={'3. Invoices'}
          content={<OverviewInvoice invoiceData={invoiceData} rentalRequestData={rentalRequestData} />}
        />

        <SequentialCard
          onlyVisible={'Dealer'}
          title={'3. Rental Agreement'}
          content={
            <OverviewRentalAgreement
              customerFirstName={rentalRequestData.customerFirstName}
              customerLastName={rentalRequestData.customerLastName}
              navigateURL={navigateURL}
              booking={mostRecentBooking}
              needsRentalAgreement={needsRentalAgreement}
            />
          }
        />

        <SequentialCard
          onlyVisible={'Dealer'}
          title={'4. Return Car'}
          content={
            <OverviewReturnCar
              rentalRequestData={rentalRequestData}
              mostRecentBooking={mostRecentBooking}
              needsRentalAgreement={needsRentalAgreement}
              setViewReturnModalVisible={setViewReturnModalVisible}
            />
          }
        />

        <SequentialCard
          onlyVisible={'Dealer'}
          title={'5. Checklist Car'}
          content={<OverviewChecklist rentalRequestData={rentalRequestData} setViewChecklistCar={setViewChecklistCar} />}
        />
      </div>
    </>
  );
}
export default Overview;
