import axios from 'axios';
import { Auth } from 'aws-amplify';
import { IAllBooking, IAllStatus, IAllStatusResponse, IBookingResponse, IDashboard } from '@scanny-app/loopy-loop';

interface FetchDashboardParams {
  locations?: string[];
  programs?: string[];
}

export const fetchDashboardData = async ({ locations, programs }: FetchDashboardParams): Promise<IDashboard | null> => {
  try {
    const data = await Auth.currentSession();
    const token = data.getAccessToken().getJwtToken();

    const response = await axios.post(
      `${process.env.REACT_APP_ENDPOINT_URL}/v1/dashboard`,
      JSON.stringify({
        ...(locations?.length && { dealerships: locations }),
        ...(programs?.length && { programs: programs }),
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (!response) return null;

    const allStatusCountUpdate: IAllStatus = { Rejected: 0, Pending: 0, Approved: 0 };
    const allBookingCountUpdate: IAllBooking = {
      'In Progress': 0,
      'No booking': 0,
      Scheduled: 0,
      Completed: 0,
    };

    response.data.compositeResponse[0].body.records.forEach((element: IAllStatusResponse) => {
      if (element.Status__c in allStatusCountUpdate) {
        allStatusCountUpdate[element.Status__c as keyof IAllStatus] = element.expr0;
      }
    });

    response.data.compositeResponse[3].body.records.forEach((element: IBookingResponse) => {
      if (element.Booking_Status__c in allBookingCountUpdate) {
        allBookingCountUpdate[element.Booking_Status__c as keyof IAllBooking] += 1;
      }
    });

    let hireDayTotal = 0;
    let hireDaysInvoiced = 0;
    let currentHireDays = 0;

    response.data.compositeResponse[3].body.records.forEach((element: IBookingResponse) => {
      if (element?.Hire__r?.Days_on_Hire__c) {
        hireDayTotal += element.Hire__r.Days_on_Hire__c;

        if (element.Booking_Status__c === 'In Progress') {
          currentHireDays += element.Hire__r.Days_on_Hire__c;
        }
        if (element.Booking_Status__c === 'Completed') {
          hireDaysInvoiced += element.Hire__r.Days_on_Hire__c;
        }
      }
    });

    return {
      numberReachingDayLimit: response.data.compositeResponse[2].body.totalSize,
      totalRentalRequests: allStatusCountUpdate.Rejected + allStatusCountUpdate.Approved + allStatusCountUpdate.Pending,
      allStatusCount: allStatusCountUpdate,
      allBookingCount: allBookingCountUpdate,
      numberOutstandingTotal: response.data.compositeResponse[1].body.records[0].expr0,
      invoicesReceived: response.data.compositeResponse[4].body.records[0].expr1,
      totalDaysOnHire: hireDayTotal,
      hireDaysInvoiced: hireDaysInvoiced,
      currentHireDays: currentHireDays,
      collectionDataLoading: false,
    };
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    return null;
  }
};
