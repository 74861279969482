import { useEffect, useState, useContext } from 'react';
import { Input, Select } from 'antd';
import { convertRentalRequestToExcel } from '@scanny-app/loan-cars/src/helpers/ConvertRentalRequestToExcel';
import { exportToExcel, formatTitle } from '@scanny-app/loan-cars/src/helpers/ExportToExcel';
import { InitialFilters } from '@scanny-app/loan-cars/src/helpers/GetFilterConfig';
import FilterData from '@scanny-app/loan-cars/src/helpers/FilterData';
import { getFilterConfig } from '@scanny-app/loan-cars/src/helpers/GetFilterConfig';
import { DealershipContext, IRentalRequest, UserContext } from '@scanny-app/loopy-loop';

const { Option } = Select;

type SearchColumnsProps<T> = {
  dataSource: IRentalRequest[];
  onFilterChange: (data: T[], hasFiltered: boolean) => void;
  combinationColumns: [string, string][];
  columnsToSearch: string[];
  searchPlaceholder: string;
  apiCompleted: boolean;
};

function SearchColumns<T extends object>({
  dataSource,
  combinationColumns,
  columnsToSearch,
  searchPlaceholder,
  onFilterChange,
  apiCompleted,
}: SearchColumnsProps<T>) {
  const userData = useContext(UserContext);
  const { rules } = useContext(DealershipContext);
  const queryParams = new URLSearchParams(window.location.search);
  const filterParam = queryParams.get('filter') || '';
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [filteredData, setFilteredData] = useState<IRentalRequest[]>([]);
  const [filterState, setFilterState] = useState(getFilterConfig(filterParam, userData?.profileType || ''));
  const isNearingDayLimit = filterParam === 'extension-outstanding';
  const isInvoiceReceived = rules.showInvoices ? filterParam === 'invoice-received' : false;
  const { program, location } = useContext(DealershipContext);
  const doSortData = (data: IRentalRequest[]) => {
    return data.sort((a, b) => {
      const dateA = new Date(isInvoiceReceived ? a.hireInvoiceCreatedDate : a.createdDate).getTime();
      const dateB = new Date(isInvoiceReceived ? b.hireInvoiceCreatedDate : b.createdDate).getTime();
      return dateB - dateA;
    });
  };

  const programHasInvoices = program.programData
    .filter((item) => item.showDealershipsInvoices === rules.showInvoices)
    .map((item) => item.id);

  const selectedProgramHasInvoices =
    program.selectedProgram.length > 0
      ? program.selectedProgram.filter((id) => programHasInvoices.includes(id))
      : programHasInvoices;

  let selectedProgram;
  if (isInvoiceReceived) {
    if (location.locationIds.length === 0) {
      selectedProgram = program.selectedProgram;
    } else {
      if (selectedProgramHasInvoices.length > 0) {
        selectedProgram = selectedProgramHasInvoices;
      } else {
        selectedProgram = ['Not Found'];
      }
    }
  } else {
    selectedProgram = program.selectedProgram;
  }

  const filteredDataFunction = FilterData(
    combinationColumns,
    dataSource,
    columnsToSearch,
    location.selectedLocation,
    selectedProgram,
    filterState.bookingStatusFilter.value as string[],
    filterState.approvalFilter.value as string[],
    filterState.invoiceStatusFilter.value as string[],
    filterState.searchTerm.value as string,
    isNearingDayLimit,
    isInvoiceReceived,
  );

  useEffect(() => {
    handleFilterChange('', '');
  }, [location.selectedLocation, program.selectedProgram]);

  useEffect(() => {
    setFilterState(getFilterConfig(filterParam, userData?.profileType || ''));
  }, [filterParam, dataSource]);

  useEffect(() => {
    setFilteredData(doSortData(filteredDataFunction));
  }, [filterState]);

  useEffect(() => {
    onFilterChange(filteredData as T[], apiCompleted);
  }, [filteredData]);

  const handleFilterChange = (newValue: string[] | string, filterName: keyof InitialFilters) => {
    setFilterState((prevState: InitialFilters) => ({
      ...prevState,
      [filterName]: {
        ...prevState[filterName],
        value: newValue,
      },
    }));
  };

  const handleReset = () => {
    setFilterState(getFilterConfig(filterParam, userData?.profileType || ''));
  };
  const handleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const bookingStatusFilter = [
    { label: 'On Hire', value: 'In Progress' },
    { label: 'Scheduled', value: 'Scheduled' },
    { label: 'Completed', value: 'Completed' },
    { label: 'No Booking', value: 'No booking' },
  ];

  const approvalFilter = [
    { label: 'Approved', value: 'Approved' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Rejected', value: 'Rejected' },
  ];

  return (
    <div className="flex gap-16 mb-16 lg-flex-column tb-rental-request-filter">
      <div className={`flex gap-16 w-60 lg-w-full lg-flex-column content-space-between ${isFilterVisible && 'lg-hidden'}`}>
        {isInvoiceReceived && (
          <div className="flex w-full text-xs middle italic">
            Only records of customers who have invoices are displayed on this table.
          </div>
        )}
        {isNearingDayLimit && (
          <div className="flex w-full text-xs middle italic">
            Only records of customers who are approaching their hire day limit are displayed on this table.
          </div>
        )}
        {location.locationIds.length === 0 && (
          <div className="search-field w-full max-w-400" data-name="Dealership">
            <Select
              value={location.selectedLocation}
              onChange={(value) => location.setSelectedLocation(value)}
              className="w-full"
              placeholder="All Dealerships"
              allowClear
              showSearch
              mode="multiple"
            >
              {location.locationsOptions &&
                location.locationsOptions.map((item, index) => (
                  <Option key={`${item.value}-${index}`} value={item.value || ''}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          </div>
        )}
        {!(isNearingDayLimit || isInvoiceReceived) && (
          <div className="search-field w-full" data-name="Approval Status">
            <Select
              value={filterState.approvalFilter.value}
              disabled={filterState.approvalFilter.disabled || filterState.bookingStatusFilter.value.length >= 1}
              onChange={(value) => handleFilterChange(value, 'approvalFilter')}
              className="w-full approval-status-filter"
              mode="multiple"
              placeholder="Approval Status"
              allowClear
            >
              {approvalFilter &&
                approvalFilter.map((item, index) => (
                  <Option key={`${item.value}-${index}`} value={item.value || ''}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          </div>
        )}
        {!(isNearingDayLimit || isInvoiceReceived) && (
          <div className="search-field w-full" data-name="Booking Status">
            <Select
              value={filterState.bookingStatusFilter.value}
              onChange={(value) => handleFilterChange(value, 'bookingStatusFilter')}
              className="w-full booking-status-filter"
              placeholder="Booking Status"
              mode="multiple"
              allowClear
              disabled={
                filterState.bookingStatusFilter.disabled ||
                (filterState.approvalFilter.value.length >= 1 && !filterState.approvalFilter.value.includes('Approved'))
              }
            >
              {bookingStatusFilter &&
                bookingStatusFilter.map((item, index) => (
                  <Option key={`${item.value}-${index}`} value={item.value || ''}>
                    {item.label}
                  </Option>
                ))}
            </Select>
          </div>
        )}
      </div>

      <div className="flex gap-16 w-40 lg-w-full">
        <div className="search-field w-full">
          <Input
            placeholder={searchPlaceholder}
            onChange={(event) => handleFilterChange(event.target.value as string, 'searchTerm')}
            value={filterState.searchTerm.value}
            allowClear
          />
        </div>
        <div className="flex gap-8">
          <button className="btn-filter" onClick={handleFilter}>
            {isFilterVisible ? 'Filter' : 'Hide'}
          </button>
          <button
            className="btn-export"
            onClick={() =>
              exportToExcel(formatTitle(filterState, filterParam), convertRentalRequestToExcel(filteredData as IRentalRequest[]))
            }
          >
            Export
          </button>
          <button
            className="btn-reset"
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchColumns;
