import { DealershipContext, IRentalRequest } from '@scanny-app/loopy-loop';
import { useContext } from 'react';

export const NearDayLimit = (rentalRequestData: IRentalRequest, returnType: string) => {
  const { program } = useContext(DealershipContext);
  const recordProgram = program.programData.filter((item) => item.id === rentalRequestData.submittedToCompany)[0];
  const maxHireDays = rentalRequestData.maxHireDays ? rentalRequestData.maxHireDays : recordProgram.hireDayLimit;
  const approvedHireDays = rentalRequestData.approvedHireDays ? rentalRequestData.approvedHireDays : recordProgram.hireDayLimit;

  let approvedCondition;
  if (rentalRequestData.maxHireDays !== null && rentalRequestData.approvedHireDays !== null) {
    approvedCondition = maxHireDays === approvedHireDays;
  }

  const isNearDayLimit =
    rentalRequestData.hireNumberOfDays &&
    rentalRequestData.bookingStatus === 'In Progress' &&
    rentalRequestData.hireNumberOfDays >= approvedHireDays - 5;

  const filterNearDayLimit =
    rentalRequestData.hireNumberOfDays &&
    rentalRequestData.bookingStatus === 'In Progress' &&
    rentalRequestData.hireNumberOfDays >= approvedHireDays - 5;

  let extensionStatus;
  if (rentalRequestData.maxHireDays === null && rentalRequestData.approvedHireDays === null) {
    extensionStatus = 'No extension';
  } else if (rentalRequestData.rejectedHireExtensionCount === 1) {
    extensionStatus = 'Rejected';
  } else {
    extensionStatus = approvedCondition ? 'Approved' : 'Pending';
  }

  if (returnType === 'isNearDayLimit') {
    return isNearDayLimit;
  } else if (returnType === 'extensionStatus') {
    return extensionStatus;
  } else if (returnType === 'filterNearDayLimit') {
    return filterNearDayLimit;
  }
};
