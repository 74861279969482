import { createContext } from 'react';
import { IBranch } from '@scanny-app/loopy-loop';
export interface IUser {
  USER_TYPE?: string;
  firstName: string;
  lastName: string;
  email: string;
  portalAccess?: boolean;
  profileType?: string;
  portalServices?: string[];
  currentCompany?: string;
  companyName?: string;
  masterUser?: boolean;
  branch?: string;
  userId?: string;
  Branch?: IBranch;
  programIds?: string[];
  locationIds?: string[];
}

export const UserContext = createContext<IUser | null>(null);

export const EmptyUser: IUser = {
  USER_TYPE: '',
  firstName: '',
  lastName: '',
  email: '',
  profileType: '',
  portalServices: [],
  currentCompany: 'NOT_FOUND',
  companyName: 'NOT_FOUND',
  masterUser: false,
  branch: '',
  userId: '',
  programIds: [],
  locationIds: [],
};
