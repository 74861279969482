import Modal from 'antd/es/modal/Modal';
import { MyIcon } from '@scanny-app/loopy-loop';

interface ApprovalVORProps {
  open: boolean;
  onCancel: () => void;
  onOk: () => void;
  accountName: string;
}

function ApprovalVOR({ open, onCancel, onOk, accountName }: ApprovalVORProps) {
  const modalTitle = (
    <div className="w-full flex flex-row gap-8 middle">
      <MyIcon icon="IconWarning" />
      <h3 className="m-0 text-sm">Sending to {accountName} for approval</h3>
    </div>
  );

  return (
    <Modal
      open={open}
      title={modalTitle}
      okText="Agree"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={onOk}
      className="custom-modal left-menu"
      width={400}
      centered
    >
      <div className="my-24">
        <p className="m-0">{accountName} requires all loan cars to have a parts VOR order number. </p>
        <p className="m-4">As this request does not meet this criteria it will be sent to {accountName} for approval. </p>
        <p className="m-0">Please ensure that you have included sufficient notes for this request.</p>
      </div>
    </Modal>
  );
}

export default ApprovalVOR;
