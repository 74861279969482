import { ISubMenu, IDashboard, IRules } from '@scanny-app/loopy-loop';
import { Skeleton } from 'antd';

export function SubMenuList(collectDataDashboard: IDashboard | undefined, rules: IRules) {
  const getCount = (currentSlug: string) => {
    let count = '';
    if (collectDataRentalRequest) {
      const matchingCountData = collectDataRentalRequest.find((data) => data.status === currentSlug);
      if (matchingCountData && matchingCountData.count !== undefined) {
        const actualCount = matchingCountData.count;
        count = actualCount > 99 ? '(99+)' : `(${actualCount})`;
      }
    }
    return collectDataDashboard?.collectionDataLoading ? (
      <Skeleton.Avatar size={10} shape={'circle'} style={{ display: 'flex' }} />
    ) : count === '(undefined)' ? (
      <Skeleton.Avatar size={10} shape={'circle'} style={{ display: 'flex' }} />
    ) : (
      <span className="font-semibold">{count}</span>
    );
  };

  const collectDataRentalRequest = [
    { status: 'approved', count: collectDataDashboard?.allStatusCount.Approved },
    { status: 'rejected', count: collectDataDashboard?.allStatusCount.Rejected },
    { status: 'pending', count: collectDataDashboard?.allStatusCount.Pending },
    { status: 'no-booking', count: collectDataDashboard?.allBookingCount['No booking'] },
    { status: 'scheduled', count: collectDataDashboard?.allBookingCount['Scheduled'] },
    { status: 'on-hire', count: collectDataDashboard?.allBookingCount['In Progress'] },
    { status: 'completed', count: collectDataDashboard?.allBookingCount['Completed'] },
    { status: 'invoice-received', count: collectDataDashboard?.invoicesReceived },
    { status: 'extension-outstanding', count: collectDataDashboard?.numberReachingDayLimit },
  ];
  const baseMenuItems: ISubMenu[] = [
    {
      label: 'All Rental Requests',
      slug: 'rental-requests',
      link: '/rentals/rental-requests',
    },
    {
      label: <>Approved {getCount('approved')}</>,
      slug: 'approved',
      link: '/rentals/rental-requests?filter=approved',
    },
    {
      label: <>Pending {getCount('pending')}</>,
      slug: 'pending',
      link: '/rentals/rental-requests?filter=pending',
    },
    {
      label: <>Rejected {getCount('rejected')}</>,
      slug: 'rejected',
      link: '/rentals/rental-requests?filter=rejected',
    },
    {
      label: <>No Booking {getCount('no-booking')}</>,
      slug: 'no-booking',
      link: '/rentals/rental-requests?filter=no-booking',
    },
    {
      label: <>Scheduled {getCount('scheduled')}</>,
      slug: 'scheduled',
      link: '/rentals/rental-requests?filter=scheduled',
    },
    {
      label: <>On Hire {getCount('on-hire')}</>,
      slug: 'on-hire',
      link: '/rentals/rental-requests?filter=on-hire',
    },
    {
      label: <>Completed {getCount('completed')}</>,
      slug: 'completed',
      link: '/rentals/rental-requests?filter=completed',
    },
  ];

  const invoiceMenuItem: ISubMenu = {
    label: <>Invoice Received {getCount('invoice-received')}</>,
    slug: 'invoice-received',
    link: '/rentals/rental-requests?filter=invoice-received',
  };

  const extensionMenuItem: ISubMenu = {
    label: <>Extension Outstanding {getCount('extension-outstanding')}</>,
    slug: 'extension-outstanding',
    link: '/rentals/rental-requests?filter=extension-outstanding',
  };

  const CarRentals: ISubMenu[] = [...baseMenuItems, ...(rules?.showInvoices ? [invoiceMenuItem] : []), extensionMenuItem];

  return { CarRentals };
}
