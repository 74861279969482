import { ConfigProvider, message } from 'antd';
import { useLocation, Navigate } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import React, { useState, useEffect } from 'react';
import AppRoutes from './config/AppRoutes';
import {
  LargeLoading,
  IDashboard,
  EmptyDashboard,
  ISelectOption,
  GetOptionsFromDB,
  DealershipContext,
  IUser,
  UserContext,
  EmptyUser,
  AppConfigContext,
  IAppConfig,
  CompanyNotFound,
  HeadScript,
  IProgram,
  checkImageExists,
  AccessDenied,
} from '@scanny-app/loopy-loop';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useIdleTimer } from 'react-idle-timer';
import SideMenuList from './config/SideMenuList';
import './App.css';
import { fetchPrograms, fetchDashboardData, getUserAuthData } from '@scanny-app/loan-cars/src/helpers/on-load';
Amplify.configure(awsconfig);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

function App() {
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState<IDashboard>(EmptyDashboard);
  const [reloadDashboard, setReloadDashboard] = useState(false);
  const [locationsOptions, setLocationOptions] = useState<ISelectOption[]>([]);
  const [programOptions, setProgramOptions] = useState<ISelectOption[]>([]);
  const [programData, setProgramData] = useState<IProgram[]>([]);
  const [showInvoices, setShowInvoices] = useState(false);
  const location = useLocation();
  const isSignInPage = location.pathname === '/signin';
  const isAuthenticated = Object.keys(userData).length > 0 && userData.constructor === Object;
  const [programIds, setProgramIds] = useState<string[]>([]);
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<string[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [logoImagePath, setLogoImagePath] = useState<string>('');
  const [isSpecificURL, setIsSpecificURL] = useState(false);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const currentURL = window.location.hostname.replace('www.', '').replace('https://', '');
  const genericURL = process.env.REACT_APP_GENERIC_URL?.replace('www.', '').replace('https://', '');

  const programContext = {
    programOptions,
    programData,
    programIds,
    selectedProgram,
    setSelectedProgram,
    isSpecificURL,
  };

  const locationContext = {
    locationsOptions,
    locationIds,
    selectedLocation,
    setSelectedLocation,
  };

  const rulesContext = {
    showInvoices,
  };

  const [appConfig, setAppConfig] = useState<IAppConfig>(() => ({
    logoImagePath: logoImagePath,
    SideMenuList: SideMenuList({
      collectDataDashboard: dashboardData,
      userData: userData,
      rules: rulesContext,
    }),
    showSideBarText: false,
    sideBarBackgroundColour: '#343434',
  }));

  const onIdle = () => {
    if (isAuthenticated) {
      Auth.signOut().then(() => {
        if (appConfig.onLogoutAction) appConfig.onLogoutAction();
        window.location.reload();
      });
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 7200000,
  });

  const getLocations = async () => {
    const url = `${process.env.REACT_APP_ENDPOINT_URL}/v1/get/Dealerships`;
    const optionsReturned: ISelectOption[] = await GetOptionsFromDB(
      {
        endpoint: url,
        filter: {},
      },
      (item) => item.name,
    );
    const sortedOptions = optionsReturned.sort((a, b) => a.label.localeCompare(b.label));
    setLocationOptions(sortedOptions);
  };

  const loadPrograms = async () => {
    const { programData, programOptions, error } = await fetchPrograms();
    if (error) {
      message.error(error);
      return;
    }
    setProgramData(programData);
    setProgramOptions(programOptions);
  };

  useEffect(() => {
    document.body.style.setProperty('--global-color', '#ffce2f');
    setIsLoading(true);
    const currentImagePath = `${process.env.REACT_APP_S3_PUBLIC}/${currentURL}`;
    const checkAndSetLogoPath = async () => {
      const exists = await checkImageExists(`${currentImagePath}/logo.svg`);
      if (genericURL !== currentURL && exists) {
        setLogoImagePath(`${currentImagePath}/logo.svg`);
      } else {
        setLogoImagePath(`/assets/generic-logo.svg`);
      }
    };

    const loadUserData = async () => {
      const { userData, programIds, locationIds, error } = await getUserAuthData(loadPrograms, getLocations);

      if (userData) {
        setUserData(userData);
        if (!userData.portalAccess) {
          setIsAccessDenied(true);
        }
        setProgramIds(programIds);
        if (locationIds.length > 0) {
          setLocationIds(locationIds);
        }
      } else {
        setUserData(EmptyUser);
      }
      setIsLoading(false);
    };
    checkAndSetLogoPath().then();
    Auth.currentAuthenticatedUser()
      .then(() => {
        loadUserData().then();
      })
      .catch(() => {
        setUserData({} as IUser);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setDashboardData(EmptyDashboard);
    const programHasInvoices = programData
      .filter((item) =>
        genericURL !== currentURL ? item.website === currentURL && item.showDealershipsInvoices : item.showDealershipsInvoices,
      )
      .map((item) => item.id);
    setShowInvoices(programHasInvoices.length > 0 || locationIds.length === 0);

    /** If user has more than one location, use selected location, else use its one location **/
    let locations = locationIds.length > 1 ? (selectedLocation.length === 0 ? undefined : selectedLocation) : locationIds;
    /** If `locationsOptions` is not empty, meaning this is manufacturer account, use selected options as `locationIds` will be empty **/
    if (locationsOptions.length !== 0) {
      locations = selectedLocation;
    }

    let programs: string[] | undefined;
    if (programOptions.length > 0 && isAuthenticated) {
      if (genericURL !== currentURL) {
        setIsSpecificURL(true);
        const filteredByURL = programData.filter((item) => item.website === currentURL).map((item) => item.id);

        if (filteredByURL.length === 1) {
          programs = filteredByURL;
          setSelectedProgram(programs);
          setIsAccessDenied(false);
        } else {
          setIsAccessDenied(true);
        }
      } else {
        /** If user has more than one program, use selected program, else use its one program **/
        programs = programIds.length > 1 ? (selectedProgram.length === 0 ? undefined : selectedProgram) : programIds;
        setIsSpecificURL(false);
        setIsAccessDenied(false);
      }
    }

    const loadDashboardData = async () => {
      const data = await fetchDashboardData({ locations, programs });
      if (data) {
        setDashboardData(data);
      } else {
        message.error('Failed to fetch dashboard data');
      }
    };

    if (isAuthenticated && programOptions.length > 0) loadDashboardData().then();
    setReloadDashboard(false);
  }, [reloadDashboard, locationIds, programIds]);

  useEffect(() => {
    setAppConfig((prevConfig) => ({
      ...prevConfig,
      logoImagePath: logoImagePath,
      SideMenuList: SideMenuList({
        collectDataDashboard: dashboardData,
        userData: userData,
        rules: rulesContext,
      }),
    }));
  }, [dashboardData, userData]);

  if (isLoading) return <LargeLoading />;
  if (!isLoading && !isAuthenticated && !isSignInPage) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  if (!isLoading && isAuthenticated && isSignInPage) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (!isLoading && isAuthenticated && !isSignInPage) {
    if (userData.currentCompany === 'NOT_FOUND') {
      return <CompanyNotFound />;
    }
    if (isAccessDenied) {
      return <AccessDenied />;
    }
  }

  const theme = {
    colorPrimary: '#3CCEB3',
    fontFamily: 'Inter, sans-serif',
  };

  return (
    <div className="App">
      <HeadScript
        googleAnalyticsId={{ dev: 'G-LXX2DB40ES', prod: 'G-41G71Q15YT' }}
        microsoftClarityId={{ dev: 'ov60r83yiy', prod: 'nx9m5sc4k4' }}
      />
      <ConfigProvider
        theme={{
          token: theme,
        }}
      >
        <Elements stripe={stripePromise}>
          <AppConfigContext.Provider value={appConfig}>
            <UserContext.Provider value={userData}>
              <DealershipContext.Provider
                value={{
                  program: programContext,
                  location: locationContext,
                  setReloadDashboard,
                  rules: rulesContext,
                }}
              >
                <AppRoutes collectDataDashboard={dashboardData} setReloadDataDashboard={setReloadDashboard}></AppRoutes>
              </DealershipContext.Provider>
            </UserContext.Provider>
          </AppConfigContext.Provider>
        </Elements>
      </ConfigProvider>
    </div>
  );
}

export default App;
