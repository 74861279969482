import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CognitoUser } from '@aws-amplify/auth';
import {
  logger,
  Login,
  PasswordChange,
  ResetPassword,
  LargeLoading,
  ISignIn,
  IBackgroundStyle,
  ILog,
} from '@scanny-app/loopy-loop';
import { Col, Row } from 'antd';

type imagePositionType = 'center' | 'bottom';

interface SignInProps {
  /**
   * If true, the login image will be positioned at the bottom of the page with no border.
   * If false, both the login image and the login form will be centered with a border.
   */
  isFullPage: boolean;
  /**
   * The login image path. The screen expects an image of height 600px.
   */
  imagePath: string;
  imagePosition?: imagePositionType;
  /**
   * The button attributes, including the background colour, background image.
   */
  buttonStyle?: IBackgroundStyle;
  /**
   * If the terms and conditions and privacy policy links are included
   */
  includesTermsAndPrivacy?: boolean;
}

function SignIn({ isFullPage, imagePath, buttonStyle, includesTermsAndPrivacy, imagePosition }: SignInProps) {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [currentUser, setCurrentUser] = useState<CognitoUser>({} as CognitoUser);
  const location = useLocation();

  const onFinish = async (values: ISignIn) => {
    setErrorMessage('');
    setIsLoading(true);
    values.email = values.email.trim();
    values.password = values.password.trim();

    Auth.signIn(values.email, values.password)
      .then((user) => {
        const now = new Date();
        const isoString = now.toISOString();
        localStorage.setItem('lastLogin', isoString);
        if (process.env.REACT_APP_SHOW_PASSWORD_RESET_MESSAGE !== 'true') {
          localStorage.removeItem('hasResetPassword');
        }
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setShowChangePassword(true);
          setIsLoading(false);
          setCurrentUser(user);
        } else {
          logger.push({
            sourceURL: window.location.href,
            email: values.email,
            requestType: 'POST',
            status: '200',
            description: 'forgot password',
            environment: process.env.REACT_APP_NODE_ENV || 'production',
          } as ILog);
          if (location.state?.from) {
            window.location.href = location.state.from.pathname;
          } else {
            window.location.href = '/';
          }
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setIsLoading(false);
        logger.push({
          sourceURL: window.location.href,
          email: values.email,
          requestType: 'POST',
          endpoint: error.endpoint,
          status: error.statusCode,
          message: error.message,
          description: 'sign in',
          environment: process.env.REACT_APP_NODE_ENV || 'production',
        } as ILog);
      });
  };
  const onFinishFailed = () => {};
  const loginForm = showResetPassword ? (
    <ResetPassword setShowResetPassword={setShowResetPassword} />
  ) : showChangePassword ? (
    <PasswordChange currentUser={currentUser} />
  ) : (
    <Login
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      errorMessage={errorMessage}
      setShowResetPassword={setShowResetPassword}
      button={buttonStyle ?? {}}
      includesTermsAndPrivacy={includesTermsAndPrivacy}
    />
  );
  return (
    <div className={!isFullPage ? 'FullPageComponent' : ''}>
      {isLoading && <LargeLoading />}
      {!isFullPage ? (
        <div id="signin-container">
          <Row className="sc-login w-full h-full">
            <Col xs={24} lg={13}>
              <div
                id="login-hero"
                style={{
                  backgroundImage: `url('${imagePath}')`,
                  height: '600px',
                  width: '100%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              ></div>
            </Col>
            <Col xs={24} lg={11} className="p-40">
              <div id="login-form-container" className="login-form md-w-full m-auto">
                {loginForm}
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div id="full-page-container">
          <div className="flex middle md-flex-column md-p-24">
            <div
              className={`w-60 md-w-full h-full ${imagePosition === 'center' ? 'flex middle' : imagePosition === 'bottom' ? 'flex end' : ''}`}
            >
              <img src={imagePath} className="w-full " alt="login-hero" />
            </div>

            <div className="w-40 md-w-full p-24">
              <div id="login-form-container" className="login-form md-w-full md-m-auto">
                {loginForm}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignIn;
