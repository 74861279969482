import { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Input, Typography, Card } from 'antd';
import { useNavigate } from 'react-router-dom';
import { CognitoUser } from '@aws-amplify/auth';
import { RuleObject } from 'antd/es/form';
import { ILog, logger, SmallLoading, IUser, UserContext } from '@scanny-app/loopy-loop';
interface PasswordChangeProps {
  currentUser: CognitoUser;
}

function PasswordChange({ currentUser }: PasswordChangeProps) {
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handlePasswordChange = async () => {
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    try {
      const response = await Auth.completeNewPassword(currentUser, password);
      logger.push({
        sourceURL: window.location.href,
        email: user?.email,
        requestType: 'POST',
        endpoint: response.endpoint,
        status: '200',
        message: response.message,
        description: 'new password - force change password',
        environment: process.env.REACT_APP_NODE_ENV || 'production',
      } as ILog);
      setShowSuccessMessage(true);
      setTimeout(() => {
        navigate('/');
      }, 3000);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
        logger.push({
          sourceURL: window.location.href,
          email: user?.email,
          requestType: 'POST',
          message: error?.message,
          status: '200',
          description: 'new password - force change password',
          environment: process.env.REACT_APP_NODE_ENV || 'production',
        } as ILog);
      }
    }
  };

  const checkForLeadingOrTrailingSpaces = (rule: RuleObject, value: string) => {
    if (value && (value.startsWith(' ') || value.endsWith(' '))) {
      return Promise.reject('Your password can’t start or end with a blank space');
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="mb-24 flex gap-8 flex-column">
        <h1 className="m-0">Change Password</h1>
        <p className="m-0">For security, please enter your new password</p>
      </div>
      <Form layout="vertical" onFinish={handlePasswordChange}>
        <Form.Item
          label="New Password"
          name="password"
          rules={[{ required: true, message: 'Please enter your new password' }, { validator: checkForLeadingOrTrailingSpaces }]}
        >
          <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[{ required: true, message: 'Please confirm your new password' }]}
        >
          <Input.Password value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
        </Form.Item>

        {errorMessage !== '' && <Typography.Text type="danger">{errorMessage}</Typography.Text>}

        <Form.Item>
          <button className="btn-login-form">Change Password</button>
        </Form.Item>

        {showSuccessMessage && (
          <div
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 9999,
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              justifyContent: 'center',
              backdropFilter: 'blur(10px)',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            <Card>
              <SmallLoading customText="Password successfully changed. Navigating to sign in..." />
            </Card>
          </div>
        )}
      </Form>
    </>
  );
}

export default PasswordChange;
