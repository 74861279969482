type FilterConfig = {
  value: string[] | string;
  disabled: boolean;
  hidden: boolean;
};

export type InitialFilters = {
  [key: string]: FilterConfig;
  approvalFilter: FilterConfig;
  bookingStatusFilter: FilterConfig;
  invoiceStatusFilter: FilterConfig;
  searchTerm: FilterConfig;
};

let initialFilters: InitialFilters = {
  approvalFilter: { value: [], disabled: false, hidden: false },
  bookingStatusFilter: { value: [], disabled: false, hidden: false },
  invoiceStatusFilter: { value: [], disabled: false, hidden: true },
  searchTerm: { value: '', disabled: false, hidden: false },
};
const valuesMap = {
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
  'no-booking': 'No booking',
  scheduled: 'Scheduled',
  'on-hire': 'In Progress',
  completed: 'Completed',
};

export const getFilterConfig = (filterParam: string | null, profileType: string) => {
  initialFilters = {
    ...initialFilters,
  };

  if (filterParam === 'approved' || filterParam === 'pending' || filterParam === 'rejected') {
    return {
      ...initialFilters,
      approvalFilter: {
        ...initialFilters.approvalFilter,
        value: [valuesMap[filterParam]],
        disabled: true,
      },
    };
  } else if (
    filterParam === 'no-booking' ||
    filterParam === 'scheduled' ||
    filterParam === 'on-hire' ||
    filterParam === 'completed'
  ) {
    return {
      ...initialFilters,
      bookingStatusFilter: {
        ...initialFilters.bookingStatusFilter,
        value: [valuesMap[filterParam]],
        disabled: true,
      },
      approvalFilter: {
        ...initialFilters.approvalFilter,
        value: ['Approved'],
        disabled: true,
      },
    };
  } else if (filterParam === 'invoice-received') {
    return {
      ...initialFilters,
      invoiceStatusFilter: {
        ...initialFilters.invoiceStatusFilter,
        hidden: false,
        value: [],
      },
      bookingStatusFilter: {
        ...initialFilters.bookingStatusFilter,
        hidden: true,
        value: [],
      },
      approvalFilter: {
        ...initialFilters.approvalFilter,
        hidden: true,
        value: [],
      },
    };
  } else if (filterParam === 'extension-outstanding') {
    return {
      ...initialFilters,
      bookingStatusFilter: {
        ...initialFilters.bookingStatusFilter,
        hidden: true,
        value: [],
      },
      approvalFilter: {
        ...initialFilters.approvalFilter,
        hidden: true,
        value: [],
      },
      invoiceStatusFilter: {
        ...initialFilters.invoiceStatusFilter,
        hidden: true,
        value: [],
      },
    };
  } else {
    return initialFilters;
  }
};
