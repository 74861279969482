import React, { useEffect, useContext, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import {
  NotFound,
  SignIn,
  AppContent,
  IDashboard,
  UserContext,
  CreateRentalAgreement,
  AgreementComplete,
  CompanyNotFound,
  IBooking,
  DealershipContext,
  checkImageExists,
} from '@scanny-app/loopy-loop';
import Dashboard from '../pages/SinglePages/Dashboard';
import CreateRentalRequest from '../pages/SinglePages/CreateRentalRequest';
import RentalRequests from '../pages/CarRentals/RentalRequests';
import RequestStatusUpdate from '../pages/SinglePages/RequestStatusUpdate';
import ViewRecord from '../pages/SinglePages/ViewRecord';
interface AppRoutesProps {
  collectDataDashboard: IDashboard;
  setReloadDataDashboard: (value: boolean) => void;
}

const pageTitles: { [key: string]: string } = {
  '*': 'Not Found',
  '/stripe': 'Stripe Payment',
  '/rentals': 'Rental Requests',
  '/damage-detection': 'Damage Detection',
  '/valuation': 'Valuation',
  '/module-marketplace': 'Module Marketplace',
  '/signin': 'Sign In',
  '/no-user-company': 'Company Not Found',
};

const AppRoutes = ({ collectDataDashboard, setReloadDataDashboard }: AppRoutesProps) => {
  const location = useLocation();
  const userData = useContext(UserContext);
  const { program } = useContext(DealershipContext);
  const filterParam = new URLSearchParams(window.location.search).get('filter');
  const [bookingData, setBookingData] = useState<IBooking>({} as IBooking);
  const [rentalAgreement, setRentalAgreement] = useState<string>();
  const [loginImagePath, setLoginImagePath] = useState<string>('');
  const currentURL = window.location.hostname.replace('www.', '').replace('https://', '');
  const genericURL = process.env.REACT_APP_GENERIC_URL?.replace('www.', '').replace('https://', '');

  useEffect(() => {
    let title = 'Loan Cars by Carbiz';
    for (const key in pageTitles) {
      if (location.pathname.includes(key)) {
        title = pageTitles[key];
        break;
      }
    }
    document.title = title;
  }, [location.pathname]);
  const handleBookingData = (booking: IBooking) => {
    setBookingData(booking);
  };
  useEffect(() => {
    if (bookingData && program.programData.length !== 0) {
      setRentalAgreement(program.programData.find((val) => val.id === bookingData.programId)?.rentalAgreementHTML);
    }
  }, [bookingData]);

  useEffect(() => {
    const currentImagePath = `${process.env.REACT_APP_S3_PUBLIC}/${currentURL}`;
    const checkAndSetLoginPath = async () => {
      const exists = await checkImageExists(`${currentImagePath}/login.jpg`);
      if (genericURL !== currentURL && exists) {
        setLoginImagePath(`${currentImagePath}/login.jpg`);
      } else {
        setLoginImagePath('/assets/generic-login.svg');
      }
    };

    checkAndSetLoginPath().then();
  }, []);

  return (
    <Routes>
      {userData && (
        <>
          <Route path="/" element={<AppContent pageContent={<Dashboard collectDataDashboard={collectDataDashboard} />} />} />

          <Route
            path="/rentals/rental-agreement/form-submitted/:id"
            element={
              <AppContent
                pageName="Agreement-Complete"
                pageContent={
                  <AgreementComplete
                    backLink={(id) =>
                      filterParam !== '' && filterParam !== null
                        ? `/rentals/rental-requests/view/${id}?filter=${filterParam}`
                        : `/rentals/rental-requests/view/${id}`
                    }
                  />
                }
              />
            }
          />
          {userData.portalServices?.includes('Car Rentals') && (
            <>
              {userData.profileType === 'Dealer' && (
                <Route
                  path="/rentals/rental-requests/add"
                  element={<AppContent pageContent={<CreateRentalRequest setReloadDataDashboard={setReloadDataDashboard} />} />}
                />
              )}
              <Route
                path="/rentals/rental-requests"
                element={<AppContent pageName="Rentals-Request" pageContent={<RentalRequests />} />}
              />

              {userData.profileType === 'Dealer' && (
                <>
                  <Route
                    path="/rentals/rental-agreement/:id/:hire"
                    element={
                      <AppContent
                        pageContent={
                          <CreateRentalAgreement
                            showBanner={true}
                            AgreementDescription={<div dangerouslySetInnerHTML={{ __html: rentalAgreement || '' }}></div>}
                            backLink={(id) =>
                              filterParam !== '' && filterParam !== null
                                ? `/rentals/rental-requests/view/${id}?filter=${filterParam}`
                                : `/rentals/rental-requests/view/${id}`
                            }
                            bookingDataFunction={handleBookingData}
                          />
                        }
                      />
                    }
                  />
                </>
              )}
              <Route
                path="/rentals/rental-requests/view/:id"
                element={<AppContent pageContent={<ViewRecord setReloadDataDashboard={setReloadDataDashboard} />} />}
              />

              {userData.profileType === 'Manufacturer' && (
                <>
                  <Route
                    path="/rentals/rental-requests/approve/:id"
                    element={
                      <AppContent
                        pageContent={<RequestStatusUpdate type="Approve" setReloadDataDashboard={setReloadDataDashboard} />}
                      />
                    }
                  />
                  <Route
                    path="/rentals/rental-requests/reject/:id"
                    element={
                      <AppContent
                        pageContent={<RequestStatusUpdate type="Reject" setReloadDataDashboard={setReloadDataDashboard} />}
                      />
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      )}
      <Route path="/signin" element={<SignIn isFullPage={false} imagePath={loginImagePath} includesTermsAndPrivacy />} />
      <Route path="/no-user-company" element={<CompanyNotFound />} />
      <Route path="///" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
