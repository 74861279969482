import { useContext, useState } from 'react';
import ApprovalStatusUpdate from './ApprovalStatusUpdate';
import CardComment from './CardComment';
import moment from 'moment';
import {
  LargeLoading,
  UserContext,
  IApproval,
  IRequestExtension,
  IComment,
  IRentalRequest,
  formatISO,
} from '@scanny-app/loopy-loop';

interface ApprovalTypeProps {
  type: 'Initial Approval' | 'Extension Request';
  requestExtensionData?: IRequestExtension;
  rentalRequestData: IRentalRequest;
  commentData: IComment[];
  setReloadDataRentalRequest: (value: boolean) => void;
  setReloadDataDashboard: (value: boolean) => void;
  setReloadDataComment: (value: boolean) => void;
}

function ApprovalType({
  type,
  requestExtensionData,
  rentalRequestData,
  commentData,
  setReloadDataRentalRequest,
  setReloadDataDashboard,
  setReloadDataComment,
}: ApprovalTypeProps) {
  const userData = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const isInitialApproval = type === 'Initial Approval';
  const isRequestExtension = type === 'Extension Request';
  const isManufacturer = userData?.profileType === 'Manufacturer';
  const isDealer = userData?.profileType === 'Dealer';

  let requestedBy: string = '',
    createDate: string = '',
    etaParts: string = '',
    calcDays: number = 0,
    comments: string = '',
    approvalBy: string = '',
    approvalComments: string = '',
    status: string = '',
    approvalDate: string = '',
    hireExtensionId: string | null = null;

  const processData = ({ data, isExtension, isInitial }: IApproval) => {
    requestedBy = `${data.submittedByName} / ${rentalRequestData.submittedByCompanyName}`;
    createDate = formatISO(data.createdDate).toUpperCase();
    status = data.status;
    approvalBy = `${data.statusUpdatedByName} / ${rentalRequestData.submittedToCompanyName}`;
    approvalDate = formatISO(data.statusChangedDateTime).toUpperCase();

    if (isExtension) {
      const extensionData = data as IRequestExtension;
      calcDays = moment(new Date(extensionData.partsETA)).diff(new Date(), 'days') + 1;
      etaParts = new Date(extensionData.partsETA).toLocaleDateString('en-AU');
      comments = extensionData.comments;
      approvalComments = extensionData.statusUpdateComments;
      hireExtensionId = extensionData.id;
    } else if (isInitial) {
      const rentalData = data as IRentalRequest;
      comments = `The rental request has been Approved by VOR number ${rentalData.VORNumber}. Please refer to this VOR number in any future correspondence regarding this rental.`;
      approvalComments = rentalData.statusComments;
    }
  };

  //Run Process Data
  const dataToProcess = isRequestExtension && requestExtensionData ? requestExtensionData : rentalRequestData;
  processData({ data: dataToProcess, isExtension: isRequestExtension, isInitial: isInitialApproval });

  const filteredCommentData = commentData.filter((comment) => {
    return comment.hireExtensionId === hireExtensionId;
  });

  //Component -- RequestInfo
  const RequestInfo = ({ requestedBy, createDate }: { requestedBy: string; createDate: string }) => (
    <div className="flex content-space-between md-flex-column">
      <div className="flex md-flex-column gap-8">
        <span className="font-semibold">Requested by:</span>
        {requestedBy}
      </div>
      <div>{createDate}</div>
    </div>
  );

  //Component -- ETAInfo
  const ETAInfo = () => (
    <div className="flex gap-8 md-w-full">
      <span className="font-semibold">ETA of parts:</span>
      {etaParts} {calcDays !== 0 && (calcDays === 1 ? `(in ${calcDays} day)` : `(in ${calcDays} days)`)}
    </div>
  );

  //Component -- Comments
  const Comments = () => <div className="italic mt-4">{comments}</div>;

  //Component -- WaitingFor
  const WaitingForApproval = () => (
    <div className={`waiting-approval p-16 flex flex-column gap-8 text-xs italic`}>Waiting for manufacturer approval...</div>
  );

  //Component -- DecisionInfo
  const DecisionInfo = ({
    approvalBy,
    approvalDate,
    approvalComments,
  }: {
    approvalBy: string;
    approvalDate: string;
    approvalComments: string;
  }) => (
    <div className="box-1-grey p-16 flex flex-column gap-8 text-xs">
      <div className="flex content-space-between md-flex-column">
        <div className="flex gap-8 md-w-full">
          <span className="font-semibold" style={{ width: 80 }}>
            Decided by:
          </span>
          {approvalBy}
        </div>
        <div className="flex content-end" style={{ width: 140 }}>
          {approvalDate}
        </div>
      </div>
      <div className="italic mt-4">{approvalComments}</div>
    </div>
  );

  return (
    <>
      {isLoading && <LargeLoading customText={'Please wait. Do not refresh or navigate away.'} />}

      {isDealer && commentData.length === 0 && isInitialApproval && status === 'Pending' && <WaitingForApproval />}

      {((isManufacturer && status === 'Pending') || (isDealer && filteredCommentData.length > 0 && status === 'Pending')) && (
        <ApprovalStatusUpdate
          isRequestExtension={isRequestExtension}
          requestExtensionData={requestExtensionData}
          rentalRequestData={rentalRequestData}
          setIsLoading={setIsLoading}
          setReloadDataRentalRequest={setReloadDataRentalRequest}
          setReloadDataDashboard={setReloadDataDashboard}
          setReloadDataComment={setReloadDataComment}
        />
      )}
      {approvalComments && status !== 'Pending' && (
        <DecisionInfo approvalBy={approvalBy} approvalDate={approvalDate} approvalComments={approvalComments} />
      )}
      <CardComment rentalRequestData={rentalRequestData} commentData={filteredCommentData} />

      {(isRequestExtension || (isInitialApproval && status !== 'Pending')) && (
        <div className={`box-1-grey p-16 flex flex-column gap-8 text-xs`}>
          <RequestInfo requestedBy={requestedBy} createDate={createDate} />
          {isRequestExtension && <ETAInfo />}
          {((isInitialApproval && !approvalComments) || isRequestExtension) && <Comments />}
        </div>
      )}
    </>
  );
}

export default ApprovalType;
